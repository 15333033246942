export function getFooterAppVersion() {
  //This will get replaced with a build version in the build
  return "2.0.0";
}

export function getUIVersion() {
  //This will get replaced with a build version in the build
  return "20240925.1";
}

export function getEnvironment() {
  //This will get replaced with an environment in the build
  return "production";
}

export function getAPIUrl() {
  // eslint-disable-next-line no-undef
  return API_URL;
}

export function getProcessorUrl() {
  // eslint-disable-next-line no-undef
  return PROCESSOR_URL;
}
