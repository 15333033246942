import styled from "styled-components";

export const StyledBackButtonDiv = styled.div`
  position: relative;
  top: -10px;
  padding: 0;
  margin: 0 0 5px 0;

  @media only screen and (max-width: 767px) {
    margin-left: -5%;
    padding: 0 10px;
  }
`;

export const StyledScreenHelpWithBackDiv = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  a {
    opacity: 0.8 !important;
  }

  @media only screen and (max-width: 767px) {
    margin-right: -2.5%;
  }
`;

export const StyledScreenHelpDiv = styled.div`
  margin-top: -10px;
  margin-bottom: 12px;
  display: flex;
  justify-content: right;

  a {
    opacity: 0.8 !important;
  }

  @media only screen and (max-width: 767px) {
    margin-right: -2.5%;
  }
`;

export const StyledHeaderRowDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;
  padding-top: 0;
  flex-wrap: wrap;
  row-gap: 10px;

  @media only screen and (max-width: 767px) {
    margin-left: -5%;
    margin-right: -5%;
    padding: 0 10px;
  }
`;

export const StyledHeaderRowButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
`;

export const StyledActionBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 5px;
  padding: 5px 0;
  margin-right: 5px;

  @media only screen and (max-width: 767px) {
    margin-right: -5px;
  }

  .nav-link {
    padding: 0;
    color: var(--link);

    &:hover {
      color: var(--link-active);
    }
  }
`;

export const StyledNoResultsDiv = styled.div`
  padding: 15px;
  height: 86px;
  background: var(--elevated-bg);
  border: 1px solid var(--elevated-border);
  border-radius: 3px;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 7px;

  p {
    color: var(--text-light);
    margin: 0;
    padding: 0;
  }

  i {
    font-size: 30px;
  }
`;

export const StyledRowDiv = styled.div`
  margin-top: -13px;
`;

export const StyledBlockLetter = styled.span`
  display: inline-block;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  padding: 2px 4px;
  background: var(--text-verylight);
  border-radius: 4px;
  text-align: center;
  font-style: normal;
`;

export const StyledMobileDenseTable = styled.table`
  width: 100%;
  table-layout: fixed;

  tbody tr:nth-child(even) {
    background-color: var(--table-row-bg);
  }

  td {
    padding: 4px 10px 4px 0 !important;
    vertical-align: top;
    margin: 0;
    font-size: 16px;
    line-height: 20px;
    width: 60%;

    &.dense-label {
      padding: 0;
      color: var(--text-medium);
      font-weight: 600;
      width: 40%;
    }

    span.dense-label {
      color: var(--text-medium);
      font-weight: 600;
    }
  }
`;

export const StyledKeyIndicator = styled.span`
  display: inline-block;
  min-width: 25px;
  margin-left: -30px;
  font-size: 24px !important;
  color: var(--drag-chip-border);
  transform: rotate(-45deg) scaleX(-1);
`;

export const StyledKeyHeader = styled.span`
  display: flex;
  align-items: center;
  position: relative;

  span[class="material-icons"] {
    position: absolute;
    display: inline-block;
    top: 2px;
    left: -20px;
    font-size: 18px !important;
    color: var(--drag-chip-border);
    transform: rotate(-45deg) scaleX(-1);
  }
`;
