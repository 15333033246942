import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Handle,
  Position,
  useUpdateNodeInternals,
  useViewport,
} from "@xyflow/react";
import {
  StyledCard,
  StyledCardLogList,
  StyledCardValue,
  StyledDescription,
  StyledNodeDiv,
  StyledProcessedIndicator,
  StyledTranslatedResult,
  StyledTranslatedValue,
  StyledTranslatedValues,
} from "./RuleMapCommonStyles";
import {
  getBadgeColorsFromType,
  getLogColors,
  getNodeTypeAbbreviation,
  ViewportZoomThresholds,
} from "./RuleMapHelperFunctions";
import RuleMapZoomedOutNode from "./RuleMapZoomedOutNode";
import RuleMapEntityLink from "./RuleMapEntityLink";
import RuleMapLogsLink from "./RuleMapLogsLink";
import { useRuleMaps } from "../../../contexts/RuleMapsContext";

export const DefaultNodeSize = { width: 450, height: 70 };

function RuleMapNode({ data }) {
  const updateNodeInternals = useUpdateNodeInternals();
  const { ruleMapsData } = useRuleMaps();
  const { zoom } = useViewport();
  const [nodeWidth] = useState(DefaultNodeSize.width);
  const [nodeHeight] = useState(DefaultNodeSize.height);

  useEffect(() => {
    let doNodeUpdate = false;
    if (data.layoutDirection !== ruleMapsData?.layoutDirection) {
      doNodeUpdate = true;
    }
    const shouldShowDetails = zoom >= ViewportZoomThresholds.showDetailsOver;
    if (shouldShowDetails !== ruleMapsData?.showDetails) {
      doNodeUpdate = true;
    }
    const shouldShowZoomedOutNode =
      zoom < ViewportZoomThresholds.showZoomedOutUnder;
    if (shouldShowZoomedOutNode !== ruleMapsData?.showZoomedOut) {
      doNodeUpdate = true;
    }

    if (doNodeUpdate) {
      updateNodeInternals(data.id);
    }
  }, [
    zoom,
    ruleMapsData?.showDetails,
    ruleMapsData?.showZoomedOut,
    data.layoutDirection,
    ruleMapsData?.layoutDirection,
  ]);

  const colors = getBadgeColorsFromType(
    data.type,
    data.isActualNodeLink,
    data.isProcessed
  );

  const isRejection = data.type === "Rejection";

  if (ruleMapsData?.showZoomedOut === true) {
    return <RuleMapZoomedOutNode data={data} />;
  }

  return (
    <StyledNodeDiv width={nodeWidth} height={nodeHeight}>
      {!data.isLeafNode && (
        <Handle
          type="target"
          position={
            ruleMapsData?.layoutDirection === "LR"
              ? Position.Left
              : Position.Top
          }
        />
      )}
      <StyledCard colors={colors}>
        {!data.isActualNodeLink && data.isProcessed && (
          <StyledProcessedIndicator>
            <span className="material-symbols-outlined">electric_bolt</span>
          </StyledProcessedIndicator>
        )}
        <StyledDescription
          className="flex-row-with-wrap-and-justify"
          colors={colors}
        >
          <span>
            {data.itemKey}
            {isRejection && (
              <span style={{ fontSize: "13px" }}>
                &nbsp;&nbsp;{data.description}
              </span>
            )}
          </span>
          <span className="charblock">
            {getNodeTypeAbbreviation(data.type)}
          </span>
        </StyledDescription>
        {ruleMapsData?.showDetails === true &&
          !isRejection &&
          !_.isEmpty(data.description) && (
            <StyledDescription
              className="flex-row-with-wrap-and-justify"
              colors={colors}
              style={{ fontSize: "13px" }}
            >
              <span>{data.description}</span>
            </StyledDescription>
          )}

        {data.translatedValues && !_.isEmpty(data.translatedValues.value) ? (
          <>
            <StyledTranslatedValues>
              {!_.isEmpty(data.translatedValues.value) && (
                <StyledTranslatedValue colors={colors}>
                  <span className="material-symbols-outlined">data_array</span>
                  {data.translatedValues.value}
                </StyledTranslatedValue>
              )}
              {!_.isEmpty(data.translatedValues.translated) && (
                <StyledTranslatedValue colors={colors}>
                  <span className="material-symbols-outlined">
                    prompt_suggestion
                  </span>
                  {data.translatedValues.translated}
                </StyledTranslatedValue>
              )}
            </StyledTranslatedValues>
            <StyledTranslatedResult>
              {(data.links || []).length > 0 ? (
                <RuleMapEntityLink links={data.links} />
              ) : (
                <>{data.translatedValues.result}</>
              )}
            </StyledTranslatedResult>
          </>
        ) : data.conditionals && !_.isEmpty(data.conditionals.result) ? (
          <>
            <StyledTranslatedValues>
              {!_.isEmpty(data.conditionals.initial) && (
                <StyledTranslatedValue colors={colors}>
                  <span className="material-symbols-outlined">data_array</span>
                  {data.conditionals.initial}
                </StyledTranslatedValue>
              )}
              {!_.isEmpty(data.conditionals.intermediate) &&
                data.conditionals.intermediate !==
                  data.conditionals.initial && (
                  <StyledTranslatedValue colors={colors}>
                    <span className="material-symbols-outlined">
                      prompt_suggestion
                    </span>
                    {data.conditionals.intermediate}
                  </StyledTranslatedValue>
                )}
              {!_.isEmpty(data.conditionals.translated) &&
                data.conditionals.translated !==
                  data.conditionals.intermediate && (
                  <StyledTranslatedValue colors={colors}>
                    <span className="material-symbols-outlined">
                      prompt_suggestion
                    </span>
                    {data.conditionals.translated}
                  </StyledTranslatedValue>
                )}
            </StyledTranslatedValues>
            <StyledTranslatedResult>
              {data.conditionals.result}
            </StyledTranslatedResult>
          </>
        ) : (
          <StyledCardValue>
            {(data.links || []).length > 0 ? (
              <RuleMapEntityLink links={data.links} />
            ) : (
              <>{!_.isEmpty(data.value) ? data.value : "no value"}</>
            )}
          </StyledCardValue>
        )}
        {ruleMapsData?.showDetails === true &&
          (data.ruleTableData || []).length > 0 && (
            <>
              <p style={{ margin: "10px 0 3px 0", padding: "0" }}>
                Rule Table Data
              </p>
              <StyledCardLogList style={{ margin: "0 20px 10px 20px" }}>
                {data.ruleTableData.map((rtData, idx) => (
                  <li
                    key={`rtData-${idx}`}
                    style={{
                      borderLeft: "2px solid var(--text-dark)",
                      paddingLeft: "8px",
                      marginBottom: "5px",
                    }}
                  >
                    <StyledDescription
                      colors={getLogColors()}
                      style={{
                        columnGap: "10px",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                      <span className="full-width">{rtData}</span>
                    </StyledDescription>
                  </li>
                ))}
              </StyledCardLogList>
            </>
          )}
        <RuleMapLogsLink logs={data.logs} title={data.itemKey} />
      </StyledCard>
      {!data.isRootNode && (
        <Handle
          type="source"
          position={
            ruleMapsData?.layoutDirection === "LR"
              ? Position.Right
              : Position.Bottom
          }
        />
      )}
    </StyledNodeDiv>
  );
}

export default RuleMapNode;
