import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Handle,
  Position,
  useUpdateNodeInternals,
  useViewport,
} from "@xyflow/react";
import {
  StyledCard,
  StyledCardValue,
  StyledDescription,
  StyledNodeDiv,
  StyledProcessedIndicator,
} from "./RuleMapCommonStyles";
import {
  getBadgeColorsFromType,
  getNodeTypeAbbreviation,
  ViewportZoomThresholds,
} from "./RuleMapHelperFunctions";
import RuleMapZoomedOutNode from "./RuleMapZoomedOutNode";
import RuleMapLogsLink from "./RuleMapLogsLink";
import { useRuleMaps } from "../../../contexts/RuleMapsContext";

export const DefaultNodeSize = { width: 200, height: 70 };

function RuleMapSimpleNode({ data }) {
  const updateNodeInternals = useUpdateNodeInternals();
  const { ruleMapsData } = useRuleMaps();
  const { zoom } = useViewport();
  const [nodeWidth] = useState(DefaultNodeSize.width);
  const [nodeHeight] = useState(DefaultNodeSize.height);

  useEffect(() => {
    let doNodeUpdate = false;
    if (data.layoutDirection !== ruleMapsData?.layoutDirection) {
      doNodeUpdate = true;
    }
    const shouldShowDetails = zoom >= ViewportZoomThresholds.showDetailsOver;
    if (shouldShowDetails !== ruleMapsData?.showDetails) {
      doNodeUpdate = true;
    }
    const shouldShowZoomedOutNode =
      zoom < ViewportZoomThresholds.showZoomedOutUnder;
    if (shouldShowZoomedOutNode !== ruleMapsData?.showZoomedOut) {
      doNodeUpdate = true;
    }

    if (doNodeUpdate) {
      updateNodeInternals(data.id);
    }
  }, [
    zoom,
    ruleMapsData?.showDetails,
    ruleMapsData?.showZoomedOut,
    data.layoutDirection,
    ruleMapsData?.layoutDirection,
  ]);

  const colors = getBadgeColorsFromType(
    data.type,
    data.isActualNodeLink,
    data.isProcessed
  );

  if (ruleMapsData?.showZoomedOut === true) {
    return <RuleMapZoomedOutNode data={data} />;
  }

  return (
    <StyledNodeDiv width={nodeWidth} height={nodeHeight}>
      {!data.isLeafNode && (
        <Handle
          type="target"
          position={
            ruleMapsData?.layoutDirection === "LR"
              ? Position.Left
              : Position.Top
          }
        />
      )}
      <StyledCard colors={colors}>
        {!data.isActualNodeLink && data.isProcessed && (
          <StyledProcessedIndicator>
            <span className="material-symbols-outlined">electric_bolt</span>
          </StyledProcessedIndicator>
        )}
        <StyledDescription
          className="flex-row-with-wrap-and-justify"
          colors={colors}
        >
          <span>{data.itemKey}</span>
          <span className="charblock">
            {getNodeTypeAbbreviation(data.type)}
          </span>
        </StyledDescription>
        {ruleMapsData?.showDetails === true && !_.isEmpty(data.description) && (
          <StyledDescription
            className="flex-row-with-wrap-and-justify"
            colors={colors}
            style={{ fontSize: "13px" }}
          >
            <span>{data.description}</span>
          </StyledDescription>
        )}
        <StyledCardValue>
          {!_.isEmpty(data.value) ? data.value : "no value"}
        </StyledCardValue>

        <RuleMapLogsLink logs={data.logs} title={data.itemKey} />
      </StyledCard>
      {!data.isRootNode && (
        <Handle
          type="source"
          position={
            ruleMapsData?.layoutDirection === "LR"
              ? Position.Right
              : Position.Bottom
          }
        />
      )}
    </StyledNodeDiv>
  );
}

export default RuleMapSimpleNode;
