import React, { createContext, useContext, useReducer } from "react";
import { ContextProviderActions } from "../constants/ContextProviderActions";
import { applyNodeChanges, applyEdgeChanges } from "@xyflow/react";

function reducer(state, action) {
  switch (action.type) {
    // When a rule map is loaded, do a full reset on all state - use initialState instead of state to get defaults.
    case ContextProviderActions.loadRuleMapForClaim:
      return {
        ...initialState,
        ruleMap: action.payload === null ? null : action.payload.ruleMap,
        claim: action.payload === null ? null : action.payload.claim,
        selectedNodeId: "-1",
        isModuleViewMode: false,
      };

    case ContextProviderActions.openRuleMapLogsDialog:
      return {
        ...state,
        logs: action.payload.logs,
        logsDialogTitle: action.payload.title,
        showLogsDialog: true,
      };

    case ContextProviderActions.closeRuleMapLogsDialog:
      return {
        ...state,
        logs: [],
        logsDialogTitle: "",
        showLogsDialog: false,
      };

    case ContextProviderActions.loadRuleMapRejectionsList:
      return {
        ...state,
        rejectionsList: action.payload,
      };

    case ContextProviderActions.loadRuleMapResponseFieldsList:
      return {
        ...state,
        responseFieldsList: action.payload,
      };

    case ContextProviderActions.selectRuleMapRejection:
      return {
        ...state,
        selectedRejection: action.payload.selectedRejection,
        selectedResponseField: action.payload.selectedResponseField,
        showPossiblePaths: action.payload.selectedRejection !== "-1",
        selectedNodeId: "-1",
        isModuleViewMode: false,
      };

    case ContextProviderActions.selectRuleMapResponseField:
      return {
        ...state,
        selectedRejection: action.payload.selectedRejection,
        selectedResponseField: action.payload.selectedResponseField,
        selectedNodeId: "-1",
        isModuleViewMode: false,
      };

    case ContextProviderActions.resetRuleMapSelections:
      return {
        ...state,
        selectedRejection: "-1",
        selectedResponseField: "-1",
        showPossiblePaths: false,
        selectedNodeId: "-1",
        doRefreshLayout: true,
        finalLayoutComplete: false,
        showModules: true,
        isModuleViewMode: false,
      };

    case ContextProviderActions.setRuleMapShowPossiblePaths:
      return {
        ...state,
        showPossiblePaths: action.payload,
      };

    case ContextProviderActions.setRuleMapLayoutDirection:
      return {
        ...state,
        layoutDirection: action.payload,
        doRefreshLayout: true,
        finalLayoutComplete: false,
        selectedNodeId: "-1",
      };

    case ContextProviderActions.setRuleMapLayoutDirectionWithoutRefresh:
      return {
        ...state,
        layoutDirection: action.payload,
      };

    case ContextProviderActions.setRuleMapShowDetails:
      return {
        ...state,
        showDetails: action.payload,
        showZoomedOut: false,
      };

    case ContextProviderActions.setRuleMapShowZoomedOut:
      return {
        ...state,
        showZoomedOut: action.payload,
        showDetails: false,
      };

    case ContextProviderActions.setRuleMapNodes:
      return {
        ...state,
        nodes: action.payload,
      };

    case ContextProviderActions.setRuleMapEdges:
      return {
        ...state,
        edges: action.payload,
      };

    case ContextProviderActions.setRuleMapModules:
      return {
        ...state,
        modules: action.payload,
      };

    case ContextProviderActions.onRuleMapNodesChange:
      return {
        ...state,
        nodes: applyNodeChanges(action.payload, state.nodes),
      };

    case ContextProviderActions.onRuleMapEdgesChange:
      return {
        ...state,
        edges: applyEdgeChanges(action.payload, state.edges),
      };

    case ContextProviderActions.setRuleMapLayoutedNodesAndEdges:
      // This is called after the dagre layout engine runs on the nodes and edges.
      return {
        ...state,
        nodes: action.payload.layoutedNodes,
        edges: action.payload.layoutedEdges,
      };

    case ContextProviderActions.setRuleMapFinalLayoutedNodesAndEdges:
      // This is called after the dagre layout is run the second time after node sizes and positions are set.
      return {
        ...state,
        nodes: action.payload.layoutedNodes,
        edges: action.payload.layoutedEdges,
        finalLayoutComplete: true,
        doRefreshLayout: false,
      };

    case ContextProviderActions.doRuleMapRefreshLayout:
      // This is called whenever we want to refresh the nodes without generating new ones
      return {
        ...state,
        doRefreshLayout: true,
        finalLayoutComplete: false,
      };

    case ContextProviderActions.selectRuleMapNode: {
      const isModule = action.payload.selectedNodeType === "ModuleNode";

      return {
        ...state,
        selectedNodeId: action.payload.selectedNodeId,
        selectedModule: action.payload.selectedNodeName,
        isModuleViewMode: isModule,
        finalLayoutComplete: !isModule,
      };
    }

    case ContextProviderActions.setRuleMapShowModules:
      return {
        ...state,
        showModules: action.payload,
      };

    default:
      throw new Error(
        `Unhandled action type in RuleMapsContext: ${action.type.toString()}`
      );
  }
}

const initialState = {
  ruleMap: null,
  nodes: [],
  edges: [],
  modules: [],
  finalLayoutComplete: false,
  doRefreshLayout: false,
  claim: null,
  logs: [],
  logsDialogTitle: "",
  showLogsDialog: false,
  selectedRejection: "-1",
  rejectionsList: [],
  selectedResponseField: "-1",
  responseFieldsList: [],
  showPossiblePaths: false,
  layoutDirection: "TB",
  showDetails: false,
  showZoomedOut: true,
  selectedNodeId: "-1",
  showModules: true,
  selectedModule: "-1",
  isModuleViewMode: false,
};

const RuleMaps = createContext({
  ruleMapsData: initialState,
  setRuleMapsData: () => null,
});

// eslint-disable-next-line react/prop-types
const RuleMapsProvider = ({ children }) => {
  const [ruleMapsData, setRuleMapsData] = useReducer(reducer, initialState);

  return (
    <RuleMaps.Provider value={{ ruleMapsData, setRuleMapsData }}>
      {children}
    </RuleMaps.Provider>
  );
};

export default RuleMapsProvider;
export const useRuleMaps = () => useContext(RuleMaps);
