import React, { useEffect, useState } from "react";
import {
  Handle,
  Position,
  useUpdateNodeInternals,
  useViewport,
} from "@xyflow/react";
import {
  StyledCard,
  StyledCardLeafNodeList,
  StyledCardValue,
  StyledDescription,
  StyledNodeDiv,
} from "./RuleMapCommonStyles";
import {
  getBadgeColorsFromType,
  getNodeTypeAbbreviation,
  ViewportZoomThresholds,
} from "./RuleMapHelperFunctions";
import { useRuleMaps } from "../../../contexts/RuleMapsContext";

export const DefaultNodeSize = { width: 450, height: 70 };

function RuleMapModuleNode({ data }) {
  const updateNodeInternals = useUpdateNodeInternals();
  const { zoom } = useViewport();
  const { ruleMapsData } = useRuleMaps();
  const [nodeWidth] = useState(DefaultNodeSize.width);
  const [nodeHeight] = useState(DefaultNodeSize.height);

  useEffect(() => {
    let doNodeUpdate = false;
    if (data.layoutDirection !== ruleMapsData?.layoutDirection) {
      doNodeUpdate = true;
    }
    const shouldShowDetails = zoom >= ViewportZoomThresholds.showDetailsOver;
    if (shouldShowDetails !== ruleMapsData?.showDetails) {
      doNodeUpdate = true;
    }
    const shouldShowZoomedOutNode =
      zoom < ViewportZoomThresholds.showZoomedOutUnder;
    if (shouldShowZoomedOutNode !== ruleMapsData?.showZoomedOut) {
      doNodeUpdate = true;
    }

    if (doNodeUpdate) {
      updateNodeInternals(data.id);
    }
  }, [
    zoom,
    ruleMapsData?.showDetails,
    ruleMapsData?.showZoomedOut,
    data.layoutDirection,
    ruleMapsData?.layoutDirection,
  ]);

  const colors = getBadgeColorsFromType(
    data.type,
    data.isActualNodeLink,
    data.isProcessed
  );

  const subnodes = (data.subnodes || []).sort(
    (a, b) => a.type.localeCompare(b.type) || a.name.localeCompare(b.name)
  );
  const subnodeCount = subnodes.length;

  return (
    <StyledNodeDiv width={nodeWidth} height={nodeHeight}>
      <Handle
        type="target"
        position={
          ruleMapsData?.layoutDirection === "LR" ? Position.Left : Position.Top
        }
      />
      <StyledCard colors={colors} className="rule-map-module">
        <StyledDescription
          className="flex-row-with-wrap-and-justify"
          colors={colors}
        >
          <span>{data.itemKey}</span>
          <span className="charblock">MODULE</span>
        </StyledDescription>
        <StyledCardValue>
          {`${subnodeCount} ${subnodeCount === 1 ? "subnode" : "subnodes"}`}
        </StyledCardValue>

        {ruleMapsData?.showDetails === true && subnodeCount > 0 && (
          <StyledCardLeafNodeList>
            {subnodes.map((n, lidx) => (
              <li key={`leaf-${lidx}-${n.name}`}>
                <StyledDescription
                  colors={getBadgeColorsFromType(n.type)}
                  style={{ columnGap: "10px", marginBottom: "5px" }}
                >
                  <span className="charblock">
                    {getNodeTypeAbbreviation(n.type, false)}
                  </span>
                  <span>{n.name}</span>
                  <span
                    className={`option-member-name ${
                      (n.value || "").length > 100 ? "full-width" : ""
                    }`}
                  >
                    {n.value}
                  </span>
                </StyledDescription>
              </li>
            ))}
          </StyledCardLeafNodeList>
        )}
      </StyledCard>

      <Handle
        type="source"
        position={
          ruleMapsData?.layoutDirection === "LR"
            ? Position.Right
            : Position.Bottom
        }
      />
    </StyledNodeDiv>
  );
}

export default RuleMapModuleNode;
