import React from "react";
import { disableAnimations } from "../../../services/General";
import { Modal } from "react-bootstrap";
import HelpLink from "../../common/ui/HelpLink";
import { StyledDescription } from "./RuleMapCommonStyles";
import {
  getBadgeColorsFromType,
  getNodeTypeAbbreviation,
} from "./RuleMapHelperFunctions";

function RuleMapEdgeDialog({ showDialog, title, onCloseDialog, data }) {
  const childNodeColors = getBadgeColorsFromType(
    data.childNode?.data.type,
    data.childNode?.data.isActualNodeLink,
    data.childNode?.data.isProcessed
  );

  const parentNodeColors = getBadgeColorsFromType(
    data.parentNode?.data.type,
    data.parentNode?.data.isActualNodeLink,
    data.parentNode?.data.isProcessed
  );

  return (
    <Modal
      show={showDialog}
      onHide={onCloseDialog}
      animation={!disableAnimations()}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {title}
          <HelpLink
            path="/RuleMaps/RuleMap-Screen&anchor=logs-dialog"
            label=""
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>{"Edge from "}</span>
        <StyledDescription
          className="flex-row-with-wrap-and-justify"
          colors={childNodeColors}
          style={{ margin: "4px 0 5px 0" }}
        >
          <span>{data.childNode?.data.itemKey}</span>
          <span className="charblock">
            {getNodeTypeAbbreviation(data.childNode?.data.type)}
          </span>
        </StyledDescription>
        <span>{" to "}</span>
        <StyledDescription
          className="flex-row-with-wrap-and-justify"
          colors={parentNodeColors}
          style={{ margin: "4px 0 40px 0" }}
        >
          <span>{data.parentNode?.data.itemKey}</span>
          <span className="charblock">
            {getNodeTypeAbbreviation(data.parentNode?.data.type)}
          </span>
        </StyledDescription>
        <p>
          <span>{"Value"}</span>
          <br />
          <br />
          <span style={{ fontSize: "18px" }}>{data.fullLabel}</span>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={onCloseDialog}
          style={{ marginLeft: "12px" }}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default RuleMapEdgeDialog;
