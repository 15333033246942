import React, { useState } from "react";
import _ from "lodash";
import styled from "styled-components";

function ReadOnly({ value, label, labelStyle, copyToClipboard }) {
  const [copying, setCopying] = useState(false);

  async function setClipboard(txt) {
    await navigator.clipboard.writeText(txt);
    setCopying(true);
    window.setTimeout(() => setCopying(false), 2000);
  }

  let wrapperClass = "form-group";
  return (
    <div className={wrapperClass}>
      <label style={labelStyle || {}}>{label}</label>
      <div className="field force-wrap">
        <span>{_.isEmpty(value) ? " " : value}</span>
        {copyToClipboard ? (
          <>
            <i
              title="Copy to clipboard"
              className="material-icons pointer"
              onClick={() => setClipboard(value)}
              style={{ paddingLeft: "10px" }}
            >
              content_copy
            </i>
            {copying && <StyledFeedbackText>Copied!</StyledFeedbackText>}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

const StyledFeedbackText = styled.span`
  position: relative;
  font-weight: bold;
  left: 20px;
  color: var(--pill-blue);
  animation: feedback 2s;
  z-index: 2;
  opacity: 0;

  @keyframes feedback {
    0% {
      opacity: 0;
      top: 0;
    }
    10% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      top: -100px;
    }
  }
`;

export default ReadOnly;
