import React, { useState } from "react";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import TextInput from "../../common/input/TextInput";
import SelectInput from "../../common/input/SingleSelect";
import {
  testStepMethodTypes,
  testStepModeTypes,
} from "../../../services/General";
import { notifyWarn } from "../../../services/NotificationService";
import TextAreaInput from "../../common/input/TextAreaInput";
import ReadOnly from "../../common/input/ReadOnly";
import HelpLink from "../../common/ui/HelpLink";

function TestStepDialog({
  isAdmin,
  isReadOnly = false,
  editItem,
  setEditItem,
  showDialog,
  onCloseDialog,
  onSaveDialog,
}) {
  const [errors, setErrors] = useState({});

  function handleChange({ target }) {
    setEditItem({ ...editItem, [target.name]: target.value });
  }

  function handleStepTypeChange(option) {
    let changed = { ...editItem, testStepType: option.value };
    setEditItem(changed);
  }

  function handleStepMethodChange(option) {
    let changed = { ...editItem, testStepMethod: option.value };
    setEditItem(changed);
  }

  function formIsValid() {
    const _errors = {};
    if (_.trim(editItem.description) === "")
      _errors.description = "Step Description is required";

    if (editItem.testStepType === "API") {
      if (_.trim(editItem.url) === "") _errors.url = "URL is required";
      if (
        _.trim(editItem.expectedResponseVm) === "" &&
        (editItem.expectedStatusCode === 0 ||
          _.trim(editItem.expectedStatusCode) === "")
      ) {
        _errors.expectedResponseVm =
          "Either Expected JSON Result or Expected Status Code is required";
        _errors.expectedStatusCode =
          "Either Expected JSON Result or Expected Status Code is required";
      }
    }

    if (editItem.testStepType === "Processor") {
      if (_.trim(editItem.processorRequestBody) === "")
        _errors.processorRequestBody = "Request Body is required";
      if (_.trim(editItem.expectedResponseVm) === "")
        _errors.expectedResponseVm = "Expected Response is required";
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function onSave(event) {
    if (event) event.preventDefault();
    if (!formIsValid()) {
      notifyWarn("Please correct the errors before saving.");
      return;
    }

    // If user is not an admin, blank out the fields they don't have access to
    if (!isAdmin) {
      let changed = { ...editItem, userImpersonateOID: "", roles: "" };
      setEditItem(changed);
    }

    onSaveDialog();
  }

  const isEditMode = editItem && editItem.id;

  return (
    <>
      <Modal size="xl" show={showDialog} onHide={onCloseDialog}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isReadOnly ? "View Step" : isEditMode ? "Edit Step" : "Add Step"}
            <HelpLink path="/Testing/Test-Screen&anchor=step-dialog" label="" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isReadOnly ? (
            <>
              <ReadOnly label="Step Description" value={editItem.description} />
              <ReadOnly
                label="Step Type"
                value={
                  testStepModeTypes.find(
                    (f) => f.value === editItem.testStepType
                  )?.label
                }
              />
              {editItem.testStepType === "API" && (
                <>
                  <ReadOnly label="URL" value={editItem.url} />
                  <ReadOnly
                    label="Method"
                    value={
                      testStepMethodTypes.find(
                        (f) => f.value === editItem.testStepMethod
                      )?.label
                    }
                  />
                  <ReadOnly
                    label="Request Headers"
                    value={editItem.requestHeaders}
                  />
                  <ReadOnly
                    label="Request Body"
                    value={editItem.processorRequestBody}
                  />
                  {isAdmin && (
                    <>
                      <ReadOnly
                        label="Impersonate User OID"
                        value={editItem.userImpersonateOID}
                      />
                      <ReadOnly label="Role List" value={editItem.roles} />
                    </>
                  )}
                  <ReadOnly
                    label="Expected Status Code"
                    value={editItem.expectedStatusCode}
                  />
                </>
              )}
              {editItem.testStepType === "Processor" && (
                <>
                  <ReadOnly
                    label="Request Body"
                    value={editItem.processorRequestBody}
                  />
                  {!_.isEmpty(editItem.lastRequestBody) &&
                    editItem.lastRequestBody !==
                      editItem.processorRequestBody && (
                      <ReadOnly
                        label="Replaced Request Body"
                        value={editItem.lastRequestBody}
                        copyToClipboard={true}
                      />
                    )}
                </>
              )}
              <ReadOnly
                label="Expected Response"
                value={editItem.expectedResponseVm}
              />
            </>
          ) : (
            <>
              <TextInput
                id="description"
                label="Step Description"
                onChange={handleChange}
                placeholder="Enter a description for the step"
                name="description"
                value={editItem.description}
                error={errors.description}
                autoFocus={true}
              />
              <SelectInput
                id="testStepType"
                name="testStepType"
                label="Step Type"
                options={[...testStepModeTypes]}
                value={{
                  value: editItem.testStepType,
                  label: testStepModeTypes.find(
                    (f) => f.value === editItem.testStepType
                  )?.label,
                }}
                onChange={handleStepTypeChange}
                placeholder=""
                error={errors.testStepType}
              />
              {editItem.testStepType === "API" && (
                <>
                  <TextInput
                    id="url"
                    label="URL"
                    onChange={handleChange}
                    placeholder="Relative API path (e.g., '/group')"
                    name="url"
                    value={editItem.url}
                    error={errors.url}
                  />
                  <SelectInput
                    id="testStepMethod"
                    name="testStepMethod"
                    label="Method"
                    options={[...testStepMethodTypes]}
                    value={{
                      value: editItem.testStepMethod,
                      label: testStepMethodTypes.find(
                        (f) => f.value === editItem.testStepMethod
                      )?.label,
                    }}
                    onChange={handleStepMethodChange}
                    placeholder=""
                    error={errors.testStepMethod}
                  />
                  <TextAreaInput
                    id="requestHeaders"
                    rows="2"
                    label="Request Headers"
                    onChange={handleChange}
                    placeholder="Request headers"
                    name="requestHeaders"
                    value={editItem.requestHeaders}
                    error={errors.requestHeaders}
                  />
                  <TextAreaInput
                    id="processorRequestBody"
                    rows="3"
                    label="Request Body"
                    onChange={handleChange}
                    placeholder="Request Body"
                    name="processorRequestBody"
                    value={editItem.processorRequestBody}
                    error={errors.processorRequestBody}
                  />
                  {isAdmin && (
                    <>
                      <TextInput
                        id="userImpersonateOID"
                        label="Impersonate User OID"
                        onChange={handleChange}
                        placeholder="OID of user to impersonate"
                        name="userImpersonateOID"
                        value={editItem.userImpersonateOID}
                        error={errors.userImpersonateOID}
                      />
                      <TextInput
                        id="roles"
                        label="Role List"
                        onChange={handleChange}
                        placeholder="Comma-separated list of roles"
                        name="roles"
                        value={editItem.roles}
                        error={errors.roles}
                      />
                    </>
                  )}
                  <TextInput
                    id="expectedStatusCode"
                    label="Expected Status Code"
                    onChange={handleChange}
                    placeholder=""
                    name="expectedStatusCode"
                    value={editItem.expectedStatusCode}
                    error={errors.expectedStatusCode}
                    isNumber={true}
                  />
                </>
              )}
              {editItem.testStepType === "Processor" && (
                <>
                  <TextAreaInput
                    id="processorRequestBody"
                    rows="8"
                    label="Request Body"
                    onChange={handleChange}
                    placeholder="Request Body"
                    name="processorRequestBody"
                    value={editItem.processorRequestBody}
                    error={errors.processorRequestBody}
                  />
                </>
              )}
              <TextAreaInput
                id="expectedResponseVm"
                rows="3"
                label="Expected Response"
                onChange={handleChange}
                placeholder="Expected Response as 'Name: Value' pairs"
                name="expectedResponseVm"
                value={editItem.expectedResponseVm}
                error={errors.expectedResponseVm}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!isReadOnly && (
            <button
              type="button"
              className="btn btn-primary btn-with-icon"
              onClick={onSave}
              style={{
                minWidth: "86px",
              }}
            >
              <span className="material-icons">check</span>
              Save
            </button>
          )}
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onCloseDialog}
            style={{ marginLeft: "12px" }}
          >
            {isReadOnly ? "Close" : "Cancel"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TestStepDialog;
