import React, { useEffect, useState } from "react";
import { StyledControlPanel, StyledDescription } from "./RuleMapCommonStyles";
import SelectInput from "../../common/input/SingleSelect";
import { useRuleMaps } from "../../../contexts/RuleMapsContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import {
  getBadgeColorsFromType,
  getNodeTypeAbbreviation,
} from "./RuleMapHelperFunctions";

const TOOLBAR_SELECT_CONTROL_STYLES = {
  maxWidth: "200px",
  width: "200px",
  height: "40px",
  maxHeight: "40px",
};

const TOOLBAR_SELECT_INPUT_STYLES = {
  fontSize: "14px",
  lineHeight: "16px",
};

function RuleMapFlowToolbar({ zoomViewPort, callFitViewAllNodes }) {
  const { ruleMapsData, setRuleMapsData } = useRuleMaps();
  const [selectedResponseField, setSelectedResponseField] = useState({
    label: "(All Responses)",
    value: "-1",
  });
  const [selectedRejection, setSelectedRejection] = useState({
    label: "(All Rejections)",
    value: "-1",
  });
  const [selectedNode, setSelectedNode] = useState({
    label: "(No Node Selected)",
    value: "-1",
  });

  useEffect(() => {
    if (ruleMapsData.selectedRejection === "-1") {
      setSelectedRejection({
        label: "(All Rejections)",
        value: "-1",
      });
    }
    if (ruleMapsData.selectedResponseField === "-1") {
      setSelectedResponseField({
        label: "(All Responses)",
        value: "-1",
      });
    }
    if (ruleMapsData.selectedNodeId === "-1") {
      setSelectedNode({
        label: "(No Node Selected)",
        value: "-1",
      });
    } else {
      const list = buildNodesSelectList();
      setSelectedNode({
        label:
          list.find((l) => l.value === ruleMapsData.selectedNodeId)?.label ||
          "(Node not found)",
        value: ruleMapsData.selectedNodeId,
      });
    }
  }, [
    ruleMapsData.selectedRejection,
    ruleMapsData.selectedResponseField,
    ruleMapsData.selectedNodeId,
  ]);

  function buildOptionsSelectList(array, allLabel) {
    const options = [];

    options.push({
      label: allLabel,
      value: "-1",
    });

    for (let i = 0; i < array.length; i++) {
      options.push(array[i]);
    }

    return options;
  }

  function getNodeListLabel(node) {
    const label = (
      <StyledDescription
        className="flex-row-without-wrap"
        colors={getBadgeColorsFromType(
          node.data.type,
          node.data.isActualNodeLink,
          node.data.isProcessed
        )}
      >
        <span className="charblock" style={{ fontSize: "13px" }}>
          {getNodeTypeAbbreviation(node.data.type, true, true)}
        </span>
        <span className="option-member-name normal-case">
          {node.data.itemKey}
        </span>
      </StyledDescription>
    );
    return label;
  }

  function buildNodesSelectList() {
    const options = [];

    options.push({
      label: "(No Node Selected)",
      value: "-1",
      type: "",
      name: "",
    });

    const array = (ruleMapsData.nodes || []).sort((a, b) =>
      a.data.itemKey > b.data.itemKey ? 1 : -1
    );

    for (let i = 0; i < array.length; i++) {
      options.push({
        value: array[i].data.id,
        name: array[i].data.itemKey,
        label: getNodeListLabel(array[i]),
        type: array[i].data.type,
      });
    }

    return options;
  }

  function handleResponseFieldChanged(option) {
    setSelectedResponseField(option);
    setRuleMapsData({
      type: ContextProviderActions.selectRuleMapResponseField,
      payload: { selectedResponseField: option.value, selectedRejection: "-1" },
    });
    doRefreshAfterOperation();
  }

  function handleRejectionChanged(option) {
    setSelectedRejection(option);
    setRuleMapsData({
      type: ContextProviderActions.selectRuleMapRejection,
      payload: { selectedResponseField: "-1", selectedRejection: option.value },
    });
    doRefreshAfterOperation();
  }

  function handleSelectedNodeChanged(option, isModuleSelect = false) {
    setSelectedNode(option);
    const duration = isModuleSelect ? 50 : 500;

    if (!isModuleSelect && option.type !== "ModuleNode") {
      if (option.value !== "-1") {
        const node = ruleMapsData.nodes.find((n) => n.data.id === option.value);
        zoomViewPort(duration, node?.position);
      } else {
        callFitViewAllNodes();
      }
    }

    window.setTimeout(() => {
      setRuleMapsData({
        type: ContextProviderActions.selectRuleMapNode,
        payload: {
          selectedNodeId: option.value,
          selectedNodeName: option.name,
          selectedNodeType: option.type,
        },
      });
    }, duration + 100);
  }

  function doRefreshAfterOperation() {
    window.setTimeout(() => {
      window.requestAnimationFrame(() => {
        setRuleMapsData({
          type: ContextProviderActions.doRuleMapRefreshLayout,
          payload: null,
        });
      });
    }, 100);
  }

  function onResetSelections() {
    setRuleMapsData({
      type: ContextProviderActions.resetRuleMapSelections,
      payload: null,
    });
  }

  function handleLayoutDirectionChange(newDirection) {
    setRuleMapsData({
      type: ContextProviderActions.setRuleMapLayoutDirection,
      payload: newDirection,
    });
  }

  function handleShowPossiblePathsChange() {
    setRuleMapsData({
      type: ContextProviderActions.setRuleMapShowPossiblePaths,
      payload: !ruleMapsData.showPossiblePaths,
    });
  }

  function handleShowModulesChange() {
    setRuleMapsData({
      type: ContextProviderActions.setRuleMapShowModules,
      payload: !ruleMapsData.showModules,
    });
  }

  function onExitModuleView() {
    handleSelectedNodeChanged(
      {
        label: "(No Node Selected)",
        value: "-1",
        type: "",
      },
      true
    );
  }

  const showPossiblePaths = ruleMapsData?.showPossiblePaths || false;
  const showModules = ruleMapsData?.showModules;
  const layoutDirection = ruleMapsData?.layoutDirection || "TB";
  const isModuleViewMode = ruleMapsData?.isModuleViewMode || false;

  return (
    <>
      <StyledControlPanel position="top-left">
        <SelectInput
          id="responseFields"
          name="responseFields"
          label=""
          labelStyle={{ display: "none" }}
          options={[
            ...buildOptionsSelectList(
              ruleMapsData.responseFieldsList,
              "(All Fields)"
            ),
          ]}
          value={selectedResponseField}
          onChange={handleResponseFieldChanged}
          placeholder="Select"
          controlStyle={{
            ...TOOLBAR_SELECT_CONTROL_STYLES,
            marginRight: "10px",
          }}
          selectStyle={{ ...TOOLBAR_SELECT_INPUT_STYLES }}
        />
        <SelectInput
          id="rejections"
          name="rejections"
          label=""
          labelStyle={{ display: "none" }}
          options={[
            ...buildOptionsSelectList(
              ruleMapsData.rejectionsList,
              "(All Rejections)"
            ),
          ]}
          value={selectedRejection}
          onChange={handleRejectionChanged}
          placeholder="Select"
          controlStyle={{
            ...TOOLBAR_SELECT_CONTROL_STYLES,
            width: "250px",
            maxWidth: "250px",
            marginRight: "10px",
          }}
          selectStyle={{ ...TOOLBAR_SELECT_INPUT_STYLES }}
        />
        <SelectInput
          id="nodes"
          name="nodes"
          label=""
          labelStyle={{ display: "none" }}
          options={[...buildNodesSelectList()]}
          value={selectedNode}
          onChange={handleSelectedNodeChanged}
          placeholder="Select"
          controlStyle={{
            ...TOOLBAR_SELECT_CONTROL_STYLES,
            width: "250px",
            maxWidth: "250px",
          }}
          selectStyle={{ ...TOOLBAR_SELECT_INPUT_STYLES }}
        />
      </StyledControlPanel>
      <StyledControlPanel position="top-right">
        {!isModuleViewMode ? (
          <button
            title={
              showModules ? "Collapse nodes into modules" : "Expand all nodes"
            }
            className={showModules ? "btn-selected" : ""}
            onClick={handleShowModulesChange}
            style={{ marginRight: "12px" }}
          >
            <span className="material-symbols-outlined">
              {showModules ? "input" : "account_tree"}
            </span>
          </button>
        ) : (
          <button
            title="Exit module view"
            className="btn btn-primary btn-with-icon"
            onClick={onExitModuleView}
            style={{ marginRight: "12px", width: "80px" }}
          >
            <span className="material-symbols-outlined">close</span>
            Close
          </button>
        )}
        <button
          title={
            showPossiblePaths
              ? "Show only relevant node paths"
              : "Show all possible node paths"
          }
          className={showPossiblePaths ? "btn-selected" : ""}
          onClick={handleShowPossiblePathsChange}
          style={{ marginRight: "12px" }}
        >
          <span className="material-symbols-outlined">
            {showPossiblePaths ? "layers" : "layers_clear"}
          </span>
        </button>
        <button
          title="View in vertical orientation"
          className={layoutDirection === "TB" ? "btn-selected" : ""}
          onClick={() => handleLayoutDirectionChange("TB")}
        >
          <span className="material-symbols-outlined">desktop_portrait</span>
        </button>
        <button
          title="View in horizontal orientation"
          className={layoutDirection === "LR" ? "btn-selected" : ""}
          onClick={() => handleLayoutDirectionChange("LR")}
          style={{ marginRight: "12px" }}
        >
          <span className="material-symbols-outlined">desktop_landscape</span>
        </button>
        <button
          title="Clear selections and reset screen to the default view"
          className="btn btn-secondary"
          onClick={onResetSelections}
          style={{ marginRight: "8px" }}
        >
          <span className="material-symbols-outlined">refresh</span>
        </button>
      </StyledControlPanel>
    </>
  );
}

export default RuleMapFlowToolbar;
