import React, { useEffect, useState } from "react";
import {
  getFullLogListFromRuleMap,
  getNodeAndEdgeList,
  getRuleTableListFromRuleMap,
} from "./RuleMapHelperFunctions";
import RuleMapLayouter from "./RuleMapLayouter";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { apiLoadRuleMapForClaim } from "../../../api/RuleMapApi";
import useApi from "../../../hooks/useApi";
import { useRuleMaps } from "../../../contexts/RuleMapsContext";
import { ContextProviderActions } from "../../../constants/ContextProviderActions";
import { notifyError, notifyWarn } from "../../../services/NotificationService";
import Spinner from "../../common/ui/Spinner";
import Authorize from "../../common/layout/Authorize";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { useMobile } from "../../../hooks/useMobile";
import RuleMapLogsDialog from "./RuleMapLogsDialog";
import {
  StyledBackButtonDiv,
  StyledHeaderRowDiv,
  StyledScreenHelpWithBackDiv,
} from "../../common/layout/CommonStyledControls";
import HelpLink from "../../common/ui/HelpLink";
import StatusPill from "../../common/ui/StatusPill";
import TabControl from "../../common/layout/TabControl";
import InstantSearchInput from "../../common/input/InstantSearchInput";
import { filterLogs, LogCommon } from "../logs/LogCommon";
import ResponsiveGrid from "../../common/layout/ResponsiveGrid";
import { formatDateOnly } from "../../../services/General";

const RuleMapTabs = {
  Tab1: "Rule Map",
  Tab2: "Logs",
  Tab3: "Rule Tables",
};

function ClaimRuleMap() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const { isMobileSize, isTabletSize } = useMobile();
  const [windowSizeW, windowSizeH] = useWindowSize([800, 800]);
  const { ruleMapsData, setRuleMapsData } = useRuleMaps();
  const { loading, api: apiLoad } = useApi(apiLoadRuleMapForClaim);
  const [showPossiblePaths, setShowPossiblePaths] = useState(false);
  const [showModules, setShowModules] = useState(true);
  const [isModuleViewMode, setIsModuleViewMode] = useState(false);
  const [transactionNumber, setTransactionNumber] = useState(-1);
  const [logs, setLogs] = useState([]);
  const [ruleTables, setRuleTables] = useState([]);
  const [rtSearch, setRtSearch] = useState({}); // Dummy search state for simple grid with no search
  const [activeTab, setActiveTab] = useState(RuleMapTabs.Tab1);
  const [logHighlightText, setLogHighlightText] = useState("");

  const resId = params && params.id;

  // Remove the resizeObserver error
  //   jon, 8/23/24: This is a workaround to remove the specific errors below. These errors only display in a dev environment and
  //   are benign according to many stack overflow articles. Here is a discussion about it on the react flow github:
  //   https://github.com/xyflow/xyflow/issues/3076
  useEffect(() => {
    const errorHandler = (e) => {
      if (
        e.message.includes(
          "ResizeObserver loop completed with undelivered notifications" ||
            "ResizeObserver loop limit exceeded"
        )
      ) {
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.style.display = "none";
        }
      }
    };
    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
    };
  });

  useEffect(() => {
    // When the screen gets unloaded, reset the context state so nothing is remembered between loads of a rule map
    return () => {
      setRuleMapsData({
        type: ContextProviderActions.loadRuleMapForClaim,
        payload: null,
      });
    };
  }, []);

  useEffect(() => {
    if (auth.authenticated) {
      // Reset screen entity when id parameter changes
      setRuleMapsData({
        type: ContextProviderActions.loadRuleMapForClaim,
        payload: null,
      });

      loadRuleMap();
    }
  }, [auth.authenticated, params?.id]);

  useEffect(() => {
    if (
      ruleMapsData &&
      ruleMapsData.ruleMap !== null &&
      showPossiblePaths !== ruleMapsData.showPossiblePaths
    ) {
      handlePossiblePathsClick(ruleMapsData.showPossiblePaths);
    }
  }, [ruleMapsData?.showPossiblePaths]);

  useEffect(() => {
    if (
      ruleMapsData &&
      ruleMapsData.ruleMap !== null &&
      showModules !== ruleMapsData.showModules
    ) {
      handleShowModulesClick(ruleMapsData.showModules);
    }
  }, [ruleMapsData?.showModules]);

  useEffect(() => {
    if (
      ruleMapsData &&
      ruleMapsData.ruleMap !== null &&
      isModuleViewMode !== ruleMapsData.isModuleViewMode
    ) {
      handleModuleViewChange(ruleMapsData.isModuleViewMode);
    }
  }, [ruleMapsData?.isModuleViewMode]);

  useEffect(() => {
    if (ruleMapsData.ruleMap !== null && transactionNumber >= 0) {
      rebuildNodeAndEdges(
        ruleMapsData.ruleMap,
        transactionNumber,
        ruleMapsData.showPossiblePaths,
        ruleMapsData.claim.status,
        ruleMapsData.selectedRejection,
        ruleMapsData.selectedResponseField
      );
    }
  }, [
    ruleMapsData.ruleMap,
    ruleMapsData.selectedRejection,
    ruleMapsData.selectedResponseField,
  ]);

  useEffect(() => {
    if (windowSizeH === 0 || windowSizeW === 0) return;

    const direction =
      isMobileSize || isTabletSize || windowSizeH > windowSizeW ? "TB" : "LR";

    if (ruleMapsData && direction !== ruleMapsData.layoutDirection) {
      setRuleMapsData({
        type: ContextProviderActions.setRuleMapLayoutDirectionWithoutRefresh,
        payload: direction,
      });
    }
  }, [windowSizeH, windowSizeW, isMobileSize, isTabletSize]);

  async function loadRuleMap() {
    if (!resId) {
      setRuleMapsData({
        type: ContextProviderActions.loadRuleMapForClaim,
        payload: null,
      });
      return;
    }

    apiLoad.call(resId, (result) => {
      let vm = null;
      if (!result) {
        notifyError("Rule Map does not exist");
      } else {
        vm = result;
      }

      setRuleMapsData({
        type: ContextProviderActions.loadRuleMapForClaim,
        payload: vm,
      });

      doPostLoadRuleMap(vm);
    });
  }

  function doPostLoadRuleMap(vm) {
    const transNum = getTransactionNumberFromRuleMap(vm.ruleMap);

    // Get the full list of logs from the rule map for the Logs tab
    const rmLogs = getFullLogListFromRuleMap(vm.ruleMap, transNum);
    const filteredLogs = filterLogs(rmLogs, undefined, logHighlightText);
    setLogs(filteredLogs);

    // Get the full list of rule tables from the rule map for the Rule Tables tab
    const rmRuleTables = getRuleTableListFromRuleMap(vm.ruleMap);
    setRuleTables(rmRuleTables);

    rebuildNodeAndEdges(
      vm.ruleMap,
      transNum,
      false,
      vm.claim.status,
      ruleMapsData.selectedRejection,
      ruleMapsData.selectedResponseField
    );
  }

  function getTransactionNumberFromRuleMap(ruleMap) {
    // The transaction number of the claim is the position of the claimId in the claimIds array.
    const transNum = (ruleMap.claimIds || []).indexOf(resId);
    if (transNum < 0) {
      notifyWarn(
        `Claim Id ${resId} was not found in the rule map's claimIds array so transaction number was not found.`
      );
      return;
    }
    setTransactionNumber(transNum);
    return transNum;
  }

  function handlePossiblePathsClick(showPossiblePaths) {
    setShowPossiblePaths(showPossiblePaths);
    rebuildNodesAndRefresh();
  }

  function handleShowModulesClick(showModulesSetting) {
    setShowModules(showModulesSetting);
    rebuildNodesAndRefresh();
  }

  function handleModuleViewChange(moduleViewSetting) {
    setIsModuleViewMode(moduleViewSetting);
    rebuildNodesAndRefresh();
  }

  function rebuildNodesAndRefresh() {
    rebuildNodeAndEdges(
      ruleMapsData.ruleMap,
      transactionNumber,
      showPossiblePaths,
      ruleMapsData.claim.status,
      ruleMapsData.selectedRejection,
      ruleMapsData.selectedResponseField
    );

    window.setTimeout(() => {
      window.requestAnimationFrame(() => {
        setRuleMapsData({
          type: ContextProviderActions.doRuleMapRefreshLayout,
          payload: null,
        });
      });
    }, 100);
  }

  function rebuildNodeAndEdges(
    ruleMap,
    newTransactionNum,
    newShowPossiblePaths,
    claimStatus,
    selectedRejection,
    selectedResponseField
  ) {
    const rm = {
      ...ruleMap,
    };

    const selectedModuleId =
      ruleMapsData?.isModuleViewMode === true &&
      ruleMapsData?.selectedModule !== "-1"
        ? ruleMapsData.selectedModule
        : "-1";

    const newNodesAndEdges = getNodeAndEdgeList(
      rm,
      newTransactionNum,
      newShowPossiblePaths,
      claimStatus,
      selectedRejection,
      selectedResponseField,
      ruleMapsData?.isModuleViewMode === true
        ? false
        : ruleMapsData.showModules,
      selectedModuleId
    );

    if (
      (newNodesAndEdges.nodeList || []).length === 0 ||
      (newNodesAndEdges.edgeList || []).length === 0
    ) {
      notifyWarn(
        `The selection options resulted in a graph with either no nodes (${
          (newNodesAndEdges.nodeList || []).length
        }) or no edges (${(newNodesAndEdges.edgeList || []).length}).`
      );
      setRuleMapsData({
        type: ContextProviderActions.resetRuleMapSelections,
        payload: null,
      });
      return;
    }

    setNodes(newNodesAndEdges.nodeList);
    setEdges(newNodesAndEdges.edgeList);
    setModules(newNodesAndEdges.modules);
    setRejectionsList(newNodesAndEdges.rejections);
    setResponseFieldsList(newNodesAndEdges.responseFields);
  }

  function setNodes(nodes) {
    setRuleMapsData({
      type: ContextProviderActions.setRuleMapNodes,
      payload: nodes,
    });
  }

  function setEdges(edges) {
    setRuleMapsData({
      type: ContextProviderActions.setRuleMapEdges,
      payload: edges,
    });
  }

  function setModules(modules) {
    setRuleMapsData({
      type: ContextProviderActions.setRuleMapModules,
      payload: modules,
    });
  }

  function setRejectionsList(array) {
    setRuleMapsData({
      type: ContextProviderActions.loadRuleMapRejectionsList,
      payload: array,
    });
  }

  function setResponseFieldsList(array) {
    setRuleMapsData({
      type: ContextProviderActions.loadRuleMapResponseFieldsList,
      payload: array,
    });
  }

  function handleCloseLogsDialog() {
    setRuleMapsData({
      type: ContextProviderActions.closeRuleMapLogsDialog,
      payload: null,
    });
  }

  const working = loading || (ruleMapsData?.ruleMap || null) === null;

  return (
    <Authorize>
      <StyledBackButtonDiv>
        <button
          title="Return to previous screen"
          type="button"
          className="btn btn-link"
          onClick={() => navigate(-1)}
        >
          <i className="fa fa-angle-left"></i> Back
        </button>
        <StyledScreenHelpWithBackDiv>
          <HelpLink path="/RuleMap/Rule-Map-Screen" label="Help" />
        </StyledScreenHelpWithBackDiv>
      </StyledBackButtonDiv>
      <StyledHeaderRowDiv>
        <h1 className="flex-row-with-wrap">
          <span>Claim Rule Map</span>
          {ruleMapsData?.claim && (
            <>
              <span>
                &nbsp;for Rx #{ruleMapsData.claim.prescriptionRefNumber}&nbsp;
                &nbsp;
              </span>
              <StatusPill status={ruleMapsData.claim.friendlyStatus} />
            </>
          )}
        </h1>
      </StyledHeaderRowDiv>
      {working ? (
        <>
          <Spinner />
        </>
      ) : (
        <>
          <TabControl
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            showTabHeader={true}
            sticky={false}
            tab1Title={"Rule Map"}
            tab2Title={"Logs"}
            tab3Title={"Rule Tables"}
            tab1Children={
              <div
                style={{
                  width: `100%`,
                  height: `${
                    windowSizeH > 800 ? windowSizeH - 240 : windowSizeH
                  }px`,
                }}
              >
                <RuleMapLayouter />
              </div>
            }
            tab2Children={
              <>
                <StyledHeaderRowDiv style={{ margin: "8px 0" }}>
                  <InstantSearchInput
                    id="screenSearchInput"
                    onChange={setLogHighlightText}
                    value={logHighlightText}
                  />
                </StyledHeaderRowDiv>
                <LogCommon
                  logs={logs}
                  highlightText={logHighlightText}
                  noResultsFoundMsg="No logs found for this rule map"
                  noResultsFoundIcon="directions_run"
                />
              </>
            }
            tab3Children={
              <>
                <ResponsiveGrid
                  gridId="RuleMapRuleTables"
                  totalRecords={ruleTables.length}
                  search={rtSearch}
                  searchOrderByPropName="drOrderBy"
                  setSearch={setRtSearch}
                  dataRows={ruleTables}
                  disablePaging={true}
                  columnDefs={[
                    {
                      name: "ruleTableName",
                      label: "Rule Table",
                      disableSort: true,
                      style: { width: "30%" },
                      getValue: (row) => (
                        <button
                          className="btn btn-link link-underline"
                          onClick={() =>
                            navigate("/ruletable/" + row.ruleTableId)
                          }
                        >
                          {row.ruleTableName}
                        </button>
                      ),
                    },
                    {
                      name: "values",
                      label: "Values",
                      disableSort: true,
                      style: { width: "50%" },
                    },
                    {
                      name: "effectiveDate",
                      label: "Effective",
                      disableSort: true,
                      style: { width: "10%" },
                      getValue: (row) => formatDateOnly(row.effectiveDate),
                    },
                    {
                      name: "terminationDate",
                      label: "Termination",
                      disableSort: true,
                      style: { width: "10%" },
                      getValue: (row) => formatDateOnly(row.terminationDate),
                    },
                  ]}
                />
              </>
            }
          />
        </>
      )}
      <RuleMapLogsDialog
        showDialog={ruleMapsData.showLogsDialog}
        title={ruleMapsData.logsDialogTitle}
        logs={ruleMapsData.logs}
        onCloseDialog={handleCloseLogsDialog}
      />
    </Authorize>
  );
}

export default ClaimRuleMap;
