import React from "react";
import _ from "lodash";
import { MarkerType } from "@xyflow/react";
import {
  generateUUID,
  ruleConditionalOperators,
} from "../../../services/General";

let isRejection = false;
let isSelectionMode = false;
let isShowModulesMode = false;
let selectedModule = "-1";

//let isReversal = false;
let nodeList = [];
let edgeList = [];
let modules = [];

const MAX_LEVELS = 20;
const MAX_EDGE_LABEL_LENGTH = 20;

export const ViewportZoomThresholds = {
  showZoomedOutUnder: 0.4,
  showDetailsOver: 0.7,
};

function getStatusFromNodeForTransaction(node, transactionNum) {
  if (!node || (node.statuses || []).length < 1) return null;

  const status = node.statuses.find(
    (s) => s.transactionIndex === transactionNum
  );
  if (!status) {
    return null;
  }

  return status;
}

function getIsProcessedFromNode(node, transactionNum) {
  const status = getStatusFromNodeForTransaction(node, transactionNum);
  if (status === null) return false;

  const isProcessed =
    status.itemProcessed === true ||
    status.actionPassed === true ||
    status.actionTriggered === true;

  return isProcessed;
}

function getExistingNodeById(id) {
  const node = nodeList.find((n) => n.id === id);
  if (node) return node;
  return null;
}

function getModuleFromNode(
  node,
  id,
  nodeName,
  nodeDisplayKey,
  value,
  nodeType
) {
  if ((node.modules || []).length < 1) return null;

  // For now, assume a node will only have a single module in the array since I'm not sure how multiple would be represented in the UI.
  let module = { ...node.modules[0], id: generateUUID(), subnodes: [] };

  // Add to global list so we can track unique modules in the graph
  if (modules.findIndex((m) => m.name === module.name) < 0) {
    // Add this node's id to the module's subnodes so we know to connect edges to the module instead of the subnode
    module.subnodes.push({
      id: id,
      name: nodeName,
      value: value,
      type: nodeType,
    });

    // Save the module
    modules.push(module);
  } else {
    // This is a subnode for the module. Save its id with the module for redirecting edges
    module = modules.find((m) => m.name === module.name);
    if (module.subnodes.findIndex((s) => s.displayKey === nodeDisplayKey) < 0) {
      module.subnodes.push({
        id: id,
        name: nodeName,
        displayKey: nodeDisplayKey,
        value: value,
        type: nodeType,
      });
    }
  }

  return module;
}

function getLinkForEntityData(nodeStatus, index) {
  let screen = nodeStatus.sourceKeyTranslated;

  switch (nodeStatus.sourceKeyTranslated) {
    case "primaryDrug":
    case "compoundDrugs":
      screen = "drug";
      break;
    case "pharmacy":
    case "pharmacyNCPDP":
      screen = "serviceprovider";
      break;
    default:
      break;
  }

  const entityId = nodeStatus.entityIds[index];
  const entityName = nodeStatus.entityFriendlyIds[index];

  const linkData = {
    index: index,
    entityType: screen,
    entityId: entityId,
    entityName: entityName,
  };
  return linkData;
}

function getLinkForRuleTableRow(ruleMap, nodeStatus, index) {
  const screen = "ruletable";
  const ruleTableRowId = nodeStatus.ruleTableRowId[index];

  // Find the ruletable with this row id to get the ruleTableId and name
  const ruleTableRow = (ruleMap.ruleTableRows || []).find(
    (r) => r.rowId === ruleTableRowId
  );
  if (!ruleTableRow) {
    console.log(
      `Rule table with row id ${ruleTableRowId} not found in ruleTableRows array while building link!`
    );
    return null;
  }

  const entityId = ruleTableRow.ruleTableId;
  const entityName = `${nodeStatus.valueTranslated}`;

  const linkData = {
    index: index,
    entityType: screen,
    entityId: entityId,
    entityName: entityName,
  };
  return linkData;
}

function getDataForRuleTableRow(ruleMap, nodeStatus, index) {
  const ruleTableRowId = nodeStatus.ruleTableRowId[index];

  // Find the ruletable with this row id to get the ruleTableId and name
  const ruleTableRow = (ruleMap.ruleTableRows || []).find(
    (r) => r.rowId === ruleTableRowId
  );
  if (!ruleTableRow) {
    console.log(
      `Rule table with row id ${ruleTableRowId} not found in ruleTableRows array while fetching data!`
    );
    return null;
  }

  const values = ruleTableRow.values || [];
  const data = values.join(", ");
  return data;
}

function getValueFromNode(node, transactionNum) {
  // If this is a message node, return the message
  if (
    node.type === "TransactionMessage" ||
    node.type === "TransmissionMessage"
  ) {
    return node.message;
  }

  const status = getStatusFromNodeForTransaction(node, transactionNum);
  if (status === null) return "";

  if (status.valueTranslated && !_.isEmpty(status.valueTranslated))
    // Response field values have a valueTranslated property but all other nodes have setValue and isValueType
    return status.valueTranslated;

  if (!status.setValue || status.setValue === null) {
    return "NULL";
  }

  // Handle value types differently
  const val = "" + status.setValue;
  if (status.isValueType) return val;

  // Detect and object or array
  if (_.trim(val).indexOf("{") === 0) {
    return "{OBJECT}";
  }

  if (_.trim(val).indexOf("[{") === 0) {
    return "[ARRAY]";
  }

  // Assume this was not an array or object and return the set value
  return val;
}

function getLinksFromNode(ruleMap, node, transactionNum) {
  const status = getStatusFromNodeForTransaction(node, transactionNum);
  if (status === null) return [];
  const links = [];

  // If the node type is Entity Data, create a link to that page.
  if (
    node.sourceType === "EntityData" &&
    status.entityIds.length > 0 &&
    status.entityFriendlyIds.length > 0
  ) {
    for (let i = 0; i < status.entityIds.length; i++) {
      links.push(getLinkForEntityData(status, i));
    }
    return links;
  }

  // Also build links to rule table rows. These will have a ruleTableRowId in their status array.
  if ((status.ruleTableRowId || []).length > 0) {
    let link;
    for (let i = 0; i < status.ruleTableRowId.length; i++) {
      link = getLinkForRuleTableRow(ruleMap, status, i);
      if (link !== null) {
        links.push(link);
      }
    }
    return links;
  }

  return [];
}

function getRuleTableDataForNode(ruleMap, node, transactionNum) {
  const status = getStatusFromNodeForTransaction(node, transactionNum);
  if (status === null) return [];
  const ruleTableData = [];

  // If node has a ruleTableRowId in its status array, build an array of rule table data.
  if ((status.ruleTableRowId || []).length > 0) {
    let data;
    for (let i = 0; i < status.ruleTableRowId.length; i++) {
      data = getDataForRuleTableRow(ruleMap, status, i);
      if (data !== null) {
        ruleTableData.push(data);
      }
    }
    return ruleTableData;
  }

  return [];
}

function getRelevantLogsFromNode(node, transactionNum) {
  if (!node || (node.relevantLogs || []).length < 1) return [];
  let logs = node.relevantLogs.filter(
    (l) => l.transactionIndex === transactionNum
  );

  // Sort logs in descending order
  logs = logs.sort((a, b) => (a.logDate > b.logDate ? 1 : -1));
  logs = logs.map((log) => {
    return { message: log.message, logDate: log.logDate };
  });
  return logs;
}

function getItemNameFromNode(node, transactionNum) {
  if (!node) return "";

  // Is this is rejection? Use displaykey.
  var nodeType = getItemTypeFromNode(null, node);
  if (nodeType === "Rejection") return node.displayKey;

  // Do we have an item key?
  if (!_.isEmpty(node.itemKey)) return node.itemKey;

  // Do we have a source key?
  if (!_.isEmpty(node.sourceKey)) return node.sourceKey;

  // Do we have a key?
  if (!_.isEmpty(node.key)) return node.key;

  // Check for a source key translated in statuses
  const status = getStatusFromNodeForTransaction(node, transactionNum);
  if (status === null) return "Unknown";

  return status.sourceKeyTranslated;
}

function getDescriptionFromNode(node) {
  if (!node) return "";

  if (!_.isEmpty(node.description)) return node.description;
  return "";
}

function getItemTypeFromNode(ruleMap, node) {
  if (!node) return "Unknown";

  let type =
    node.valueType ||
    node.sourceType ||
    node.nodeType ||
    node.type ||
    (isRejection ? "Rejection" : "Unknown");

  if (!ruleMap || ruleMap === null) return "Unknown";

  if (type === "ResponseFieldValue") {
    // If this response field is listed in internal variables, change it to that instead.
    const itemID = getItemFromPropertyByDisplayKey(
      ruleMap.includedDataValues,
      node.displayKey || node.sourceKey
    );
    if (itemID !== null) {
      type = "InternalVariable";
    } else {
      // Only keep this as a response field if it is listed explicitly in response fields - otherwise make it a variable
      const itemRS = (ruleMap.responseFields || []).find(
        (i) => i.itemKey === node.sourceKey
      );
      if (!itemRS) {
        type = "InternalVariable";
      }
    }
  }

  return type;
}

function getRuleMapNodeTypeFromNodeType(nodeType) {
  let ruleMapNodeType = "ruleMapNode";

  switch (nodeType) {
    case "CodeArtifact":
      ruleMapNodeType = "ruleMapCodeArtifactNode";
      break;
    case "CodeArtifactInput":
    case "CodeArtifactOutput":
      ruleMapNodeType = "ruleMapArtifactPreReqsNode";
      break;
    case "RequestFieldValue":
      ruleMapNodeType = "ruleMapSimpleNode";
      break;
    case "ResponseFieldValue":
      ruleMapNodeType = "ruleMapSimpleNode";
      break;
    case "TransactionMessage":
    case "TransmissionMessage":
      ruleMapNodeType = "ruleMapNode";
      break;
    case "Rejection":
      ruleMapNodeType = "ruleMapNode";
      break;
    case "ModuleNode":
      ruleMapNodeType = "ruleMapModuleNode";
      break;
    default:
      break;
  }

  return ruleMapNodeType;
}

function getConditionalValuesFromNode(node, transactionNum) {
  const val = {
    initial: "",
    intermediate: "",
    translated: "",
    result: "",
  };
  if (!node) return val;

  // Look up comparison type to get label
  const conditional = ruleConditionalOperators.find(
    (c) => c.value === node.comparison
  );
  if (!conditional) {
    console.log(
      `Conditional for ${node.comparison} not found in ruleConditionalOperators!`
    );
    return val;
  }

  // Check for a value intermediate in statuses
  const status = getStatusFromNodeForTransaction(node, transactionNum);
  if (status === null) return val;

  const operator = conditional.label;

  const showValue = conditional.requiresValue;
  const srcKeyValue = getValueFromNode(node, transactionNum);
  const comparisonValues = (node.comparisonValues || []).join(", ");
  const comparisonValuesIntermediate = (
    status.comparisonValuesIntermediate || []
  ).join(", ");
  const comparisonValuesTranslated = (
    status.comparisonValuesTranslated || []
  ).join(", ");

  val.result = status.actionPassed ? "True" : "False";

  val.initial = `${node.sourceKey} ${operator}${
    showValue && !_.isEmpty(comparisonValues) ? " " + comparisonValues : ""
  }`;
  val.intermediate = `${srcKeyValue} ${operator}${
    showValue && !_.isEmpty(comparisonValuesIntermediate)
      ? " " + comparisonValuesIntermediate
      : ""
  }`;
  val.translated = `${srcKeyValue} ${operator}${
    showValue && !_.isEmpty(comparisonValuesTranslated)
      ? " " + comparisonValuesTranslated
      : ""
  }`;

  return val;
}

function getTranslatedValuesFromNode(node, transactionNum) {
  const val = { value: "", translated: "", result: "" };
  if (!node) return val;

  if (!_.isEmpty(node.itemValue)) {
    val.value = node.itemValue;
  }

  // Check for a value intermediate in statuses
  const status = getStatusFromNodeForTransaction(node, transactionNum);
  if (status === null) return val;

  if (!_.isEmpty(status.valueIntermediate)) {
    val.translated = status.valueIntermediate;

    if (_.isEmpty(val.translated) && !_.isEmpty(val.value)) {
      val.translated = val.value;
    }
  }

  // Check for a final result value
  if (!_.isEmpty(status.valueTranslated)) {
    val.result = status.valueTranslated;
  }

  return val;
}

function getItemFromPropertyByDisplayKey(propArray, key) {
  const array = propArray || [];
  if (array.length < 1) return null;
  const item = array.find((i) => i.displayKey === key || i.key === key);
  return item || null;
}

function getExistingNodeByDisplayKey(key) {
  const array = nodeList || [];
  if (array.length < 1 || _.isEmpty(key)) return null;

  const item = array.find(
    (i) => i.data?.displayKey === key || i.data?.sourceKey === key
  );
  // if (!item) {
  //   console.log(`Node key not found (count: ${array.length} ): `, key);
  // }
  return item || null;
}

export function getNodeTypeAbbreviation(
  type,
  normalSize = true,
  noIcon = false
) {
  let abbr = "";

  switch (type) {
    case "Flag":
      abbr = normalSize ? "FLAG" : "F";
      break;
    case "InternalVariable":
      abbr = normalSize ? "VAR" : "V";
      break;
    case "ResponseFieldValue":
      abbr = noIcon ? "RES" : <span className="material-icons">check</span>;
      break;
    case "Rejection":
      abbr = noIcon ? "REJ" : <span className="material-icons">close</span>;
      break;
    case "RequestFieldValue":
      abbr = normalSize ? "REQ" : "R";
      break;
    case "GroupSetting":
      abbr = normalSize ? "GRP" : "G";
      break;
    case "EntityData":
      abbr = noIcon ? "ENT" : normalSize ? "ENTITY" : "E";
      break;
    case "CodeArtifact":
      abbr = noIcon ? "ART" : normalSize ? "ARTIFACT" : "A";
      break;
    case "CodeArtifactInput":
      abbr = noIcon ? "IN" : normalSize ? "INPUT" : "I";
      break;
    case "CodeArtifactOutput":
      abbr = noIcon ? "OUT" : normalSize ? "OUTPUT" : "O";
      break;
    case "LookupTable":
      abbr = noIcon ? "LKP" : normalSize ? "LOOKUP" : "L";
      break;
    case "TransmissionMessage":
    case "TransactionMessage":
      abbr = noIcon ? "MSG" : normalSize ? "MESSAGE" : "M";
      break;
    case "ConditionalNode":
      abbr = noIcon ? "CON" : normalSize ? "COND" : "C";
      break;
    case "ModuleNode":
      abbr = noIcon ? "MOD" : normalSize ? "MODULE" : "M";
      break;
    default:
      abbr = type;
  }

  return abbr;
}

export function getLogColors() {
  let bgColor = "var(--ruleparser-blue)";
  let color = "#fff";
  let bgShadow = "transparent";

  return { color, bgColor, bgShadow };
}

export function getBadgeColorsFromType(
  type,
  isActualNodeLink = true,
  isProcessed = true
) {
  let bgColor = "var(--ruleparser-blue)";
  let color = "#fff";
  let bgShadow = "transparent";

  switch (type) {
    case "InternalVariable":
      bgColor = "var(--ruleparser-yellow)";
      color = "#000";
      break;
    case "ResponseFieldValue":
      bgColor = "var(--ruleparser-green)";
      break;
    case "Rejection":
      bgColor = "var(--ruleparser-purered)";
      break;
    case "RequestFieldValue":
      bgColor = "var(--ruleparser-blue)";
      break;
    case "GroupSetting":
      bgColor = "var(--ruleparser-gray)";
      break;
    case "CodeArtifact":
      bgColor = "var(--ruleparser-red)";
      break;
    case "CodeArtifactInput":
    case "CodeArtifactOutput":
      bgColor = "var(--ruleparser-red)";
      break;
    case "EntityData":
      bgColor = "var(--ruleparser-darkpurple)";
      break;
    case "LookupTable":
      bgColor = "var(--ruleparser-green)";
      break;
    case "TransmissionMessage":
    case "TransactionMessage":
      bgColor = "var(--ruleparser-green)";
      break;
    case "ConditionalNode":
      bgColor = "var(--ruleparser-purple)";
      break;
    case "ModuleNode":
      bgColor = "var(--rulemap-module-border)";
      color = "var(--rulemap-module-text)";
      bgShadow = "2px 2px 15px var(--rulemap-module-border)";
      break;
    default:
  }

  if (!isActualNodeLink && isProcessed) {
    color = "#000";
    bgColor = "var(--ruleparser-lightblue)";
    bgShadow = "2px 2px 15px var(--ruleparser-blue)";
  } else if (!isActualNodeLink) {
    bgColor = "var(--ruleparser-gray)";
  }

  return { color, bgColor, bgShadow };
}

export function getItemByDisplayKey(ruleMap, key) {
  // Look through each of the following properties in the rule map to find an element with the given display key.
  //   codeArtifacts, includedDataValues, includedRejections, excludedRejections
  // If not found, return null.
  if (!ruleMap) return null;

  let item = null;

  item = getItemFromPropertyByDisplayKey(ruleMap.codeArtifacts, key);
  if (item !== null) {
    item.nodeType = "CodeArtifact";
    return item;
  }

  item = getItemFromPropertyByDisplayKey(ruleMap.includedDataValues, key);
  if (item !== null) {
    item.nodeType = "IncludedDataValue";
    return item;
  }

  item = getItemFromPropertyByDisplayKey(ruleMap.includedRejections, key);
  if (item !== null) {
    item.nodeType = "IncludedRejection";
    return item;
  }

  item = getItemFromPropertyByDisplayKey(ruleMap.excludedRejections, key);
  if (item !== null) {
    item.nodeType = "ExcludedRejection";
    return item;
  }

  return null;
}

export function getRuleTableRowByRowId(ruleMap, rowId) {
  if (!ruleMap) return null;
  const array = ruleMap.ruleTableRows || [];
  if (array.length < 1) return null;

  const row = array.find((i) => i.rowId === rowId);
  return row || null;
}

function buildNode(
  ruleMap,
  id,
  field,
  transactionNum,
  isActualNodeLink = true
) {
  const nodeName = getItemNameFromNode(field, transactionNum);
  const nodeDisplayKey = field.displayKey || field.sourceKey || field.key || "";
  const description = getDescriptionFromNode(field);
  const value = getValueFromNode(field, transactionNum);
  const nodeType = getItemTypeFromNode(ruleMap, field);
  const ruleMapNodeType = getRuleMapNodeTypeFromNodeType(nodeType);
  const translatedValues = getTranslatedValuesFromNode(field, transactionNum);
  const isProcessed = getIsProcessedFromNode(field, transactionNum);
  const links = getLinksFromNode(ruleMap, field, transactionNum);
  const ruleTableData = getRuleTableDataForNode(ruleMap, field, transactionNum);
  const logs = getRelevantLogsFromNode(field, transactionNum);
  const module = getModuleFromNode(
    field,
    id,
    nodeName,
    nodeDisplayKey,
    value,
    nodeType
  );
  let isNodeHidden = false;
  if (selectedModule !== "-1" && module?.name !== selectedModule) {
    isNodeHidden = true;
  }

  const node = {
    id: id,
    position: { x: 0, y: 0 },
    data: {
      displayKey: nodeDisplayKey,
      itemKey: nodeName,
      id: id,
      value: value,
      description: description,
      type: nodeType,
      links: links,
      ruleTableData: ruleTableData,
      logs: logs,
      layoutDirection: "TB",
      translatedValues: translatedValues,
      isActualNodeLink: isActualNodeLink,
      isProcessed: isProcessed,
      module: module,
      isHidden: isNodeHidden,
    },
    type: ruleMapNodeType,
  };

  if (_.isEmpty(node.data.displayKey) || _.isEmpty(node.data.itemKey)) {
    console.log("WARNING: Both displayKey and itemKey empty in buildNode!");
  }
  return node;
}

function getModuleIfNodeIdIsSubnodeOfModule(nodeId) {
  const module = modules.find(
    (m) => m.subnodes.findIndex((s) => s.id === nodeId) >= 0
  );
  return module;
}

function addNodeIfNotExist(node) {
  const module = getModuleIfNodeIdIsSubnodeOfModule(node.id);
  if (module && isShowModulesMode) {
    node.data.isHidden = true;
  }

  if ((nodeList || []).length < 1) {
    nodeList.push(node);
    return;
  }

  const existing = nodeList.find(
    (e) =>
      e.data.displayKey === node.data.displayKey &&
      e.data.type === node.data.type
  );
  if (!existing) {
    nodeList.push(node);
  }
}

function buildEdge(
  type,
  parentNodeId,
  childNodeId,
  label = "",
  isActualNodeLink = true,
  isProcessed = true
) {
  const color = getBadgeColorsFromType(type, isActualNodeLink, isProcessed);

  // If this we are showing modules, change all edges that would otherwise connect a module's subnodes to connect to the module itself instead.
  let childId = childNodeId;
  let parentId = parentNodeId;
  if (isShowModulesMode) {
    const childModule = getModuleIfNodeIdIsSubnodeOfModule(childId);
    if (childModule) {
      childId = childModule.id;
    }
    const parentModule = getModuleIfNodeIdIsSubnodeOfModule(parentId);
    if (parentModule) {
      parentId = parentModule.id;
    }
  }

  const edge = {
    id: generateUUID(),
    source: childId,
    target: parentId,
    type: isActualNodeLink ? "smoothstep" : "default",
    animated: isActualNodeLink,
    style: {
      stroke: color.bgColor,
      strokeWidth: isActualNodeLink ? "2px" : "1px",
    },
    data: {
      isActualNodeLink: isActualNodeLink,
    },
  };

  if (isActualNodeLink) {
    edge.type = "ruleMapNormalEdge";
    edge.markerEnd = {
      type: MarkerType.ArrowClosed,
    };
  } else {
    edge.type = "ruleMapPossiblePathEdge";
  }

  if (!_.isEmpty(label)) {
    edge.data.childNode = getExistingNodeById(childId);
    edge.data.parentNode = getExistingNodeById(parentId);

    // if (edge.data.childNode === null || edge.data.parentNode === null) {
    //   console.log(
    //     "Child or parent node of edge cannot be found in building label for edge."
    //   );
    // }

    edge.data.fullLabel = label;
    edge.label =
      label.length > MAX_EDGE_LABEL_LENGTH
        ? _.truncate(label, { length: MAX_EDGE_LABEL_LENGTH })
        : label;
    edge.labelStyle = {
      fontSize: "16px",
    };
    edge.labelBgStyle = {
      fill: color.bgColor,
      fillOpacity: 0.3,
      stroke: color.bgColor,
      strokeWidth: "1px",
    };
    edge.labelShowBg = true;
    edge.labelBgPadding = [10, 5];
    edge.labelBgBorderRadius = 5;
  }

  return edge;
}

function addEdgeIfNotExist(edge) {
  if ((edgeList || []).length < 1) {
    edgeList.push(edge);
    return;
  }

  const existing = edgeList.find(
    (e) => e.source === edge.source && e.target === edge.target
  );
  if (!existing) {
    edgeList.push(edge);
  }
}

function buildModuleNode(module) {
  const type = "ModuleNode";
  const node = {
    id: module.id,
    position: { x: 0, y: 0 },
    data: {
      displayKey: module.name,
      itemKey: module.name,
      id: module.id,
      type: type,
      layoutDirection: "TB",
      subnodes: module.subnodes,
    },
    type: getRuleMapNodeTypeFromNodeType(type),
  };

  return node;
}

function buildLeafNode(ruleMap, field, transactionNum) {
  const nodeName = getItemNameFromNode(field, transactionNum);
  const description = getDescriptionFromNode(field);
  const nodeType = getItemTypeFromNode(ruleMap, field);
  const links = getLinksFromNode(ruleMap, field, transactionNum);
  const ruleTableData = getRuleTableDataForNode(ruleMap, field, transactionNum);

  const leaf = {
    itemKey: nodeName,
    value: getValueFromNode(field, transactionNum),
    description: description,
    type: nodeType,
    links: links,
    ruleTableData: ruleTableData,
  };

  return leaf;
}

function buildRunValueEntry(ruleMap, field, transactionNum) {
  // Convert all values to strings so we don't end up with true/false or a number appearing to be empty.
  const value = `${field.values[transactionNum]}`;

  const leaf = {
    key: field.key,
    value: value,
  };

  return leaf;
}

function injectDummyConditionalNode(
  ruleMap,
  field,
  fieldType,
  fieldStatus,
  transactionNum,
  isActualNodeLink,
  thisNode,
  parentId
) {
  const dummyNodeId = generateUUID();

  const displayKey = `${fieldStatus.sourceKeyTranslated}${
    fieldStatus.sourceKeyTranslated !== field.sourceKey
      ? " (" + field.sourceKey + ")"
      : ""
  }`;

  const dummyField = {
    displayKey: displayKey,
    itemKey: displayKey,
    valueType: "ConditionalNode",
    statuses: [...field.statuses],
    modules: [...(field.modules || [])],
  };
  const dummyNode = buildNode(
    ruleMap,
    dummyNodeId,
    dummyField,
    transactionNum,
    isActualNodeLink
  );
  dummyNode.data.conditionals = getConditionalValuesFromNode(
    field,
    transactionNum
  );

  addNodeIfNotExist(dummyNode);

  // Do not add artifact output edges if part of a module and we are showing modules
  const module = getModuleIfNodeIdIsSubnodeOfModule(dummyNode.id);
  if (!isShowModulesMode || (isShowModulesMode && !module)) {
    // Connect an edge to back to the child (if there is one)
    if (thisNode && thisNode !== null) {
      const childEdge = buildEdge(
        thisNode.data.type,
        dummyNodeId,
        thisNode.data.id,
        thisNode.data.value,
        isActualNodeLink,
        fieldStatus.actionTriggered
      );
      addEdgeIfNotExist(childEdge);
    }

    // Interject this node in between the child and its parent
    const parentEdge = buildEdge(
      fieldType,
      parentId,
      dummyNodeId,
      dummyNode.data.conditionals.result,
      isActualNodeLink,
      fieldStatus.actionTriggered
    );
    addEdgeIfNotExist(parentEdge);
  }
}

function injectArtifactOutputNode(
  ruleMap,
  field,
  fieldType,
  transactionNum,
  isActualNodeLink,
  thisNode,
  childId,
  parentId,
  parentValue
) {
  const artifactOutputNodeId = generateUUID();
  // Store the id of the output node so we can use it later when other nodes link into this same artifact.
  thisNode.data.outputNodeId = artifactOutputNodeId;

  const artifactOutputField = {
    displayKey: `${field.displayKey} Output`,
    itemKey: `${field.itemKey}`,
    valueType: "CodeArtifactOutput",
    statuses: [...field.statuses],
    modules: [...(field.modules || [])],
  };
  const artifactOutputNode = buildNode(
    ruleMap,
    artifactOutputNodeId,
    artifactOutputField,
    transactionNum,
    isActualNodeLink
  );

  const artifactOutputUpdatesKeys = [];
  for (let i = 0; i < field.updatesKeys.length; i++) {
    artifactOutputUpdatesKeys.push(
      buildLeafNode(ruleMap, field.updatesKeys[i], transactionNum)
    );
  }

  artifactOutputNode.data.leafNodes = artifactOutputUpdatesKeys;
  artifactOutputNode.data.isRootNode = parentId === null;
  addNodeIfNotExist(artifactOutputNode);

  // Do not add artifact output edges if part of a module and we are showing modules
  const module = getModuleIfNodeIdIsSubnodeOfModule(artifactOutputNode.id);
  if (!isShowModulesMode || (isShowModulesMode && !module)) {
    // Connect an edge to back to the artifact child
    const artifactOutputEdge = buildEdge(
      "CodeArtifact",
      artifactOutputNodeId,
      childId,
      `${artifactOutputUpdatesKeys.length} ${
        artifactOutputUpdatesKeys.length === 1 ? "output" : "outputs"
      }`,
      isActualNodeLink,
      artifactOutputNode.data.isProcessed
    );
    addEdgeIfNotExist(artifactOutputEdge);

    // Interject this node in between the artifact and its parent
    if (parentId !== null) {
      const parentEdge = buildEdge(
        fieldType,
        parentId,
        artifactOutputNodeId,
        !_.isEmpty(parentValue) ? parentValue : thisNode.data.value,
        isActualNodeLink,
        thisNode.data.isProcessed
      );
      addEdgeIfNotExist(parentEdge);
    }
  }
}

function traverseActionPrerequisitesForNode(
  level,
  ruleMap,
  transactionNum,
  includePossiblePaths,
  field,
  parentId,
  parentVal,
  isActualNodeLink,
  maxLevel = MAX_LEVELS
) {
  if (level > maxLevel) return;

  // Loop through this node's action prerequisites - if a leafnode, add it to the output array. If the tree goes deeper,
  //   do not continue tracing back but do not draw a node.
  // Filter actionPrerequisites to only those that contain a status with the given transacitonIndex.
  const array = (field.actionPrerequisites || []).filter(
    (a) =>
      (a.statuses || []).findIndex(
        (s) => s.transactionIndex === transactionNum
      ) >= 0
  );
  if (array.length === 0) return [];

  const leafNodes = [];
  let nodeId = "",
    newParentId = parentId;
  let item, itemType, rootNode, node, edge, itemStatus;
  const fieldType = getItemTypeFromNode(ruleMap, field);

  for (let i = 0; i < array.length; i++) {
    item = array[i];
    itemType = getItemTypeFromNode(ruleMap, item);

    // Skip any items without a status since these have no value
    itemStatus = getStatusFromNodeForTransaction(item, transactionNum);
    if (itemStatus === null) continue;

    // If this item has no parent, it must be the root node we want, so add the node now without checking if it already exists
    if (parentId === null) {
      newParentId = generateUUID();
      rootNode = buildNode(
        ruleMap,
        newParentId,
        item,
        transactionNum,
        isActualNodeLink
      );
      rootNode.data.isRootNode = true;
      // addNodeIfNotExist(rootNode);
      nodeList.push(rootNode); // Always add the root nodes
    }

    // If the item has a parent node that we have already added, simply draw an edge to it from the parent and exit.
    node = getExistingNodeByDisplayKey(itemStatus.sourceDisplayKey);
    if (node !== null) {
      edge = buildEdge(
        fieldType,
        newParentId,
        !_.isEmpty(node.data.outputNodeId) ? node.data.outputNodeId : node.id,
        rootNode
          ? rootNode.data.value
          : !_.isEmpty(parentVal)
          ? parentVal
          : node.data.value,
        isActualNodeLink,
        node.data.isProcessed
      );
      addEdgeIfNotExist(edge);
      continue;
    }

    // If this action prerequisite is an "IsCompared" condition that was triggered (actionTriggered = true), add a dummy node for the condition,
    //   interjected between the parent and child nodes.
    const isConditionNode =
      item.condition === "IsCompared" && itemStatus.actionTriggered === true;

    // If we don't have a source display key, this is a leaf node (this happens for group settings, request values, and entity data), so add it to the array and continue
    if (
      _.isEmpty(itemStatus.sourceDisplayKey) &&
      fieldType === "CodeArtifact"
    ) {
      // Is this a conditional node? If so, inject a dummy node here. Otherwise, add to leaf nodes which gets placed inside the artifact input node.
      if (isConditionNode) {
        // Add a leaf node above this condition
        node = getExistingNodeByDisplayKey(itemStatus.sourceDisplayKey);
        if (node !== null) {
          nodeId = node.data.id;
        } else {
          nodeId = generateUUID();
          node = buildNode(
            ruleMap,
            nodeId,
            item,
            transactionNum,
            isActualNodeLink
          );
          node.data.isLeafNode = true;
          addNodeIfNotExist(node);
        }

        injectDummyConditionalNode(
          ruleMap,
          item,
          itemType,
          itemStatus,
          transactionNum,
          isActualNodeLink,
          node,
          parentId
        );
      } else {
        leafNodes.push(buildLeafNode(ruleMap, item, transactionNum));
      }
      continue;
    }

    // If a normal leaf node, add a node and edge for it
    const sources = item.possibleSources || [];
    if (_.isEmpty(itemStatus.sourceDisplayKey) && sources.length === 0) {
      nodeId = generateUUID();
      node = buildNode(ruleMap, nodeId, item, transactionNum, isActualNodeLink);
      node.data.isLeafNode = true;
      addNodeIfNotExist(node);

      if (isConditionNode) {
        injectDummyConditionalNode(
          ruleMap,
          item,
          itemType,
          itemStatus,
          transactionNum,
          isActualNodeLink,
          node,
          parentId
        );
      } else {
        edge = buildEdge(
          node.data.type,
          newParentId,
          nodeId,
          node.data.value,
          isActualNodeLink,
          node.data.isProcessed
        );
        addEdgeIfNotExist(edge);
      }
      continue;
    }

    // We have a source display key which means the tree continues, so trace back the anscestors
    const nextNode = getItemByDisplayKey(ruleMap, itemStatus.sourceDisplayKey);
    if (nextNode !== null) {
      // Get parent value for the edge
      const val = getValueFromNode(item, transactionNum);
      traverseTreeAndBuildNodesAndEdges(
        level + 1,
        ruleMap,
        transactionNum,
        includePossiblePaths,
        nextNode,
        newParentId,
        rootNode ? rootNode.data.value : val,
        isActualNodeLink,
        null,
        maxLevel
      );
    }

    // Do we have any possible sources to traverse?
    let source, nextSourceNode;
    if (includePossiblePaths && sources.length > 0) {
      for (let j = 0; j < sources.length; j++) {
        source = sources[j];

        nextSourceNode = getItemByDisplayKey(ruleMap, source.sourceDisplayKey);
        if (nextSourceNode !== null) {
          traverseTreeAndBuildNodesAndEdges(
            level + 1,
            ruleMap,
            transactionNum,
            includePossiblePaths,
            nextSourceNode,
            newParentId,
            rootNode ? rootNode.data.value : "",
            false,
            null,
            maxLevel
          );
        }
      }
    }
  }

  return leafNodes;
}

function traverseTreeAndBuildNodesAndEdges(
  level,
  ruleMap,
  transactionNum,
  includePossiblePaths,
  field,
  parentId,
  parentValue = "",
  isActualNodeLink = true,
  rootNode = null,
  maxLevel = MAX_LEVELS
) {
  if (level > maxLevel) return;

  const fieldType = getItemTypeFromNode(ruleMap, field);

  // If this node is a root node (no parent) and is part of a module, create a standalone node first so the module has something to connect to.
  //   Only need to do this for rejections and messages. Response Fields are already handled because they do not appear in modules.
  let rootNodeId = "";
  if (
    isShowModulesMode &&
    parentId === null &&
    (isRejection ||
      field.type === "TransmissionMessage" ||
      field.type === "TransactionMessage")
  ) {
    rootNodeId = generateUUID();
    rootNode = buildNode(
      ruleMap,
      rootNodeId,
      { ...field, modules: [] },
      transactionNum,
      isActualNodeLink
    );
    rootNode.data.isRootNode = true;
    addNodeIfNotExist(rootNode);
  }

  // If this node already exists, draw the edge but stop traversing since we would already have traced this path in another branch.
  let id;
  let thisNode =
    parentId === null ? null : getExistingNodeByDisplayKey(field.displayKey);
  if (thisNode !== null) {
    id = !_.isEmpty(thisNode.data.outputNodeId)
      ? thisNode.data.outputNodeId
      : thisNode.id;
    addEdgeIfNotExist(
      buildEdge(
        fieldType,
        parentId,
        id,
        thisNode.data.value,
        isActualNodeLink,
        thisNode.data.isProcessed
      )
    );
    return;
  }

  // We have a new node, so add it to the graph and traverse its dependency tree - but skip the root nodes (start with its prereqs).
  id = generateUUID();
  if (
    parentId !== null ||
    level > 1 ||
    isRejection ||
    selectedModule !== "-1"
  ) {
    thisNode = buildNode(ruleMap, id, field, transactionNum, isActualNodeLink);

    // Add the relevant logs to the artifact.
    const runValues = [];
    const logs = field.runValues || [];

    for (let i = 0; i < logs.length; i++) {
      runValues.push(buildRunValueEntry(ruleMap, logs[i], transactionNum));
    }
    thisNode.data.runValues = runValues;

    if (
      thisNode !== null &&
      (parentId !== null || isRejection || selectedModule !== "-1")
    ) {
      // Go ahead and add the node so it is available in our node list for the duplicate check later.
      thisNode.data.isRootNode = level === 1;
      addNodeIfNotExist(thisNode);
    }

    // If we created a rootNode above, add an edge to it
    if (rootNode !== null) {
      const rootEdge = buildEdge(
        fieldType,
        rootNode.id,
        id,
        rootNode.data.value,
        isActualNodeLink,
        rootNode.data.isProcessed
      );
      addEdgeIfNotExist(rootEdge);
    }

    // Only build an edge if we have a parent
    if (parentId !== null || selectedModule !== "-1") {
      // If this node is a code artifact and has updates keys, create a new node to hold these outputs and build edges to the parent
      const isArtifactWithUpdatesKeys =
        thisNode.data.type === "CodeArtifact" &&
        (field.updatesKeys || []).length > 0;
      if (isArtifactWithUpdatesKeys) {
        injectArtifactOutputNode(
          ruleMap,
          field,
          fieldType,
          transactionNum,
          isActualNodeLink,
          thisNode,
          id,
          parentId,
          parentValue
        );
      } else {
        thisNode.data.isRootNode = level === 1;
        addNodeIfNotExist(thisNode);

        const parentEdge = buildEdge(
          fieldType,
          parentId,
          id,
          !_.isEmpty(parentValue) ? parentValue : thisNode.data.value,
          isActualNodeLink,
          thisNode.data.isProcessed
        );
        addEdgeIfNotExist(parentEdge);
      }
    }
  }

  // If this node is a message node with a sourceDisplayKey, track its ancestors back
  if (
    (field.type === "TransmissionMessage" ||
      field.type === "TransactionMessage") &&
    !_.isEmpty(field.sourceDisplayKey)
  ) {
    const nextNode = getItemByDisplayKey(ruleMap, field.sourceDisplayKey);
    traverseTreeAndBuildNodesAndEdges(
      level,
      ruleMap,
      transactionNum,
      includePossiblePaths,
      nextNode,
      id,
      getValueFromNode(field, transactionNum),
      isActualNodeLink,
      rootNode
    );
    return;
  }

  // If this node has no action prerequisites, add a leaf node and stop
  const actionPrereqs = (field.actionPrerequisites || []).filter(
    (a) =>
      (a.statuses || []).findIndex(
        (s) => s.transactionIndex === transactionNum
      ) >= 0
  );
  if (actionPrereqs.length < 1) {
    if (thisNode === null) {
      thisNode = buildNode(
        ruleMap,
        id,
        field,
        transactionNum,
        isActualNodeLink
      );
    }
    thisNode.data.isRootNode = level === 1;
    thisNode.data.isLeafNode = true;
    addNodeIfNotExist(thisNode);

    return;
  }

  // Go through this node's prerequisites and traverse their trees - collect leaf nodes for this level because these are drawn differently
  const nodeParentId = thisNode !== null ? id : parentId;
  let leafNodes = traverseActionPrerequisitesForNode(
    level,
    ruleMap,
    transactionNum,
    includePossiblePaths,
    field,
    nodeParentId,
    thisNode?.data?.value || "",
    isActualNodeLink
  );

  // If we have any leaf nodes, add a new single node to the tree to contain these since it would create too many nodes, otherwise.
  if (leafNodes.length > 0) {
    const artifactInputNodeId = generateUUID();
    const artifactInputField = {
      displayKey: `${field.displayKey} Input`,
      itemKey: `${field.itemKey}`,
      valueType: "CodeArtifactInput",
      statuses: [...field.statuses],
      modules: [...(field.modules || [])],
    };
    const artifactInputNode = buildNode(
      ruleMap,
      artifactInputNodeId,
      artifactInputField,
      transactionNum,
      isActualNodeLink
    );
    artifactInputNode.data.leafNodes = leafNodes;
    artifactInputNode.data.isLeafNode = true;
    addNodeIfNotExist(artifactInputNode);

    // Do not add artifact output edges if part of a module and we are showing modules
    const module = getModuleIfNodeIdIsSubnodeOfModule(artifactInputNode.id);
    if (!isShowModulesMode || (isShowModulesMode && !module)) {
      // Connect an edge to back to the artifact parent
      const artifactInputEdge = buildEdge(
        "CodeArtifact",
        id,
        artifactInputNodeId,
        `${leafNodes.length} ${leafNodes.length === 1 ? "input" : "inputs"}`,
        isActualNodeLink,
        artifactInputNode.data.isProcessed
      );
      addEdgeIfNotExist(artifactInputEdge);
    }
  }
}

function getRelevantLogsFromArray(array, transactionNum) {
  let logs = [];
  let relevantLogs;

  for (let i = 0; i < array.length; i++) {
    relevantLogs = (array[i].relevantLogs || []).filter(
      (r) => r.transactionIndex === transactionNum
    );

    // Return a log structure that is compatible with the LogCommon component.
    logs = logs.concat(
      relevantLogs.map((log) => {
        return {
          message: log.message,
          entryDate: log.logDate,
          logType: "Info",
          methodName: "",
        };
      })
    );
  }
  return logs;
}

export function getFullLogListFromRuleMap(ruleMap, transactionNum) {
  let logs = [];

  logs = logs.concat(
    getRelevantLogsFromArray(ruleMap.codeArtifacts, transactionNum)
  );
  logs = logs.concat(
    getRelevantLogsFromArray(ruleMap.includedDataValues, transactionNum)
  );
  logs = logs.concat(
    getRelevantLogsFromArray(ruleMap.includedRejections, transactionNum)
  );
  logs = logs.concat(
    getRelevantLogsFromArray(ruleMap.excludedRejections, transactionNum)
  );
  logs = logs.concat(
    getRelevantLogsFromArray(ruleMap.ruleTableRows, transactionNum)
  );
  logs = logs.concat(
    getRelevantLogsFromArray(ruleMap.responseFields, transactionNum)
  );
  logs = logs.concat(
    getRelevantLogsFromArray(ruleMap.messageFields, transactionNum)
  );

  const sortedLogs = logs.sort((a, b) => (a.entryDate > b.entryDate ? 1 : -1));
  return sortedLogs;
}

export function getRuleTableListFromRuleMap(ruleMap) {
  const rts = [];
  let rt;
  const array = ruleMap.ruleTableRows || [];

  for (let i = 0; i < array.length; i++) {
    rt = array[i];

    rts.push({
      ruleTableName: rt.ruleTableName,
      ruleTableId: rt.ruleTableId,
      key: rt.key,
      values: (rt.values || []).join(", "),
      effectiveDate: rt.effectiveDate,
      terminationDate: rt.terminationDate,
    });
  }

  const sortedRuleTables = rts.sort(
    (a, b) =>
      a.ruleTableName.localeCompare(b.ruleTableName) ||
      a.key.localeCompare(b.key)
  );

  return sortedRuleTables;
}

export function getInitialNodeAndEdgeListForArrayField(
  ruleMap,
  transactionNum,
  includePossiblePaths,
  array,
  fieldName
) {
  const field = getItemFromPropertyByDisplayKey(array, fieldName);

  traverseTreeAndBuildNodesAndEdges(
    1,
    ruleMap,
    transactionNum,
    includePossiblePaths,
    field,
    null,
    "",
    !isSelectionMode
  );
}

function getRejectionList(ruleMap, transactionNum, includeAll) {
  const array = (ruleMap.includedRejections || []).filter(
    (r) =>
      r.statuses.find((s) => s.transactionIndex === transactionNum)
        ?.itemProcessed === true || includeAll === true
  );
  return array;
}

function populateNodesAndEdgesForRejections(
  ruleMap,
  transactionNum,
  includePossiblePaths,
  rejections
) {
  for (let i = 0; i < rejections.length; i++) {
    getInitialNodeAndEdgeListForArrayField(
      ruleMap,
      transactionNum,
      includePossiblePaths,
      rejections,
      rejections[i].displayKey
    );
  }
}

function getMessageList(ruleMap, transactionNum) {
  const array = (ruleMap.messageFields || []).filter(
    (f) =>
      f.transactionIndex === transactionNum || f.type === "TransmissionMessage"
  );
  return array;
}

function populateNodesAndEdgesForMessages(
  ruleMap,
  transactionNum,
  includePossiblePaths,
  messages
) {
  for (let i = 0; i < messages.length; i++) {
    getInitialNodeAndEdgeListForArrayField(
      ruleMap,
      transactionNum,
      includePossiblePaths,
      messages,
      messages[i].key
    );
  }
}

function getResponseFieldList(ruleMap, transactionNum) {
  const array = ruleMap.responseFields || [];
  return array;
}

function populateNodesAndEdgesForResponseFields(
  ruleMap,
  transactionNum,
  includePossiblePaths,
  responseFields
) {
  for (let i = 0; i < responseFields.length; i++) {
    getInitialNodeAndEdgeListForArrayField(
      ruleMap,
      transactionNum,
      includePossiblePaths,
      responseFields,
      responseFields[i].displayKey
    );
  }
}

function populateNodesAndEdgesForProcessedArtifacts(
  ruleMap,
  transactionNum,
  includePossiblePaths,
  artifacts
) {
  const array = (artifacts || []).filter(
    (r) =>
      r.statuses.find((s) => s.transactionIndex === transactionNum)
        ?.itemProcessed === true
  );
  for (let i = 0; i < array.length; i++) {
    getInitialNodeAndEdgeListForArrayField(
      ruleMap,
      transactionNum,
      includePossiblePaths,
      array,
      array[i].displayKey
    );
  }
}

function populateNodesAndEdgesForProcessedIncludedDataValues(
  ruleMap,
  transactionNum,
  includePossiblePaths,
  includedDataValues
) {
  const array = (includedDataValues || []).filter(
    (r) =>
      r.statuses.find((s) => s.transactionIndex === transactionNum)
        ?.itemProcessed === true
  );

  for (let i = 0; i < array.length; i++) {
    getInitialNodeAndEdgeListForArrayField(
      ruleMap,
      transactionNum,
      includePossiblePaths,
      array,
      array[i].displayKey
    );
  }
}

function getDropdownLabel(code, description) {
  const label = (
    <>
      <span className="charblock">{code}</span>
      <span className="option-member-name normal-case">{description}</span>
    </>
  );
  return label;
}

function buildFieldListsFromRuleMap(ruleMap) {
  const rejectionList = ruleMap.includedRejections
    .map((r) => {
      return {
        label: getDropdownLabel(r.itemKey, r.itemValue),
        sortLabel: `${r.itemKey} ${r.itemValue}`,
        value: r.displayKey,
      };
    })
    .sort((a, b) => (a.sortLabel > b.sortLabel ? 1 : -1));
  const responseFieldList = ruleMap.responseFields
    .map((r) => {
      return {
        label: getDropdownLabel(r.itemKey, r.description),
        sortLabel: `${r.itemKey} ${r.description}`,
        value: r.displayKey,
      };
    })
    .sort((a, b) => (a.sortLabel > b.sortLabel ? 1 : -1));

  return { rejectionList, responseFieldList };
}

function getArrayFilteredByModule(array, moduleName) {
  const retArray = (array || []).filter(
    (r) => (r.modules || []).findIndex((m) => m.name === moduleName) >= 0
  );
  return retArray;
}

function getArrayFilteredByTransactionAndProcessed(array, transactionNum) {
  const retArray = (array || []).filter(
    (r) =>
      r.statuses.find((s) => s.transactionIndex === transactionNum)
        ?.itemProcessed === true
  );
  return retArray;
}

function populateNodesAndEdgesForArray(
  ruleMap,
  transactionNum,
  includePossiblePaths,
  array
) {
  for (let i = 0; i < array.length; i++) {
    getInitialNodeAndEdgeListForArrayField(
      ruleMap,
      transactionNum,
      includePossiblePaths,
      array,
      array[i].displayKey
    );
  }
}

function removeHiddenNodesAndEdges() {
  const finalNodeList = [];

  for (let i = 0; i < nodeList.length; i++) {
    if (nodeList[i].data.isHidden !== true) {
      finalNodeList.push(nodeList[i]);
    }
  }

  const finalEdgeList = edgeList.filter(
    (e) =>
      finalNodeList.findIndex((n) => e.source === n.id) >= 0 &&
      finalNodeList.findIndex((n) => e.target === n.id) >= 0
  );

  return { finalNodeList, finalEdgeList };
}

function getModuleNodeAndEdgeList(
  ruleMap,
  transactionNum,
  includePossiblePaths
) {
  // Loop through each array and find the top level items in each that are in the selected module
  let array = getArrayFilteredByModule(ruleMap.codeArtifacts, selectedModule);
  array = getArrayFilteredByTransactionAndProcessed(array, transactionNum);
  populateNodesAndEdgesForArray(
    ruleMap,
    transactionNum,
    includePossiblePaths,
    array
  );
  array = getArrayFilteredByModule(ruleMap.includedDataValues, selectedModule);
  array = getArrayFilteredByTransactionAndProcessed(array, transactionNum);
  populateNodesAndEdgesForArray(
    ruleMap,
    transactionNum,
    includePossiblePaths,
    array
  );
  if (isRejection) {
    array = getArrayFilteredByModule(
      ruleMap.includedRejections,
      selectedModule
    );
    array = getArrayFilteredByTransactionAndProcessed(array, transactionNum);
    populateNodesAndEdgesForArray(
      ruleMap,
      transactionNum,
      includePossiblePaths,
      array
    );
  }
  array = getArrayFilteredByModule(ruleMap.responseFields, selectedModule);
  populateNodesAndEdgesForArray(
    ruleMap,
    transactionNum,
    includePossiblePaths,
    array
  );
  array = getArrayFilteredByModule(ruleMap.messageFields, selectedModule);
  populateNodesAndEdgesForArray(
    ruleMap,
    transactionNum,
    includePossiblePaths,
    array
  );

  // Remove hidden nodes and then remove all edges that do not have corresponding source/target nodes.
  const { finalNodeList, finalEdgeList } = removeHiddenNodesAndEdges();

  // Now go through each leaf node (nodes without a source edge) and add one more layer down to each one
  selectedModule = "-1"; // Reset selected module so we can get non-module nodes now

  let leafNodes = finalNodeList.filter(
    (n) => finalEdgeList.findIndex((e) => e.source === n.id) < 0
  );

  // If no leaf nodes found, this could be because there no edges. In that case, treat all nodes as leaf nodes
  if (leafNodes.length === 0) {
    leafNodes = [...finalNodeList];
  }

  for (let i = 0; i < leafNodes.length; i++) {
    traverseTreeAndBuildNodesAndEdges(
      1,
      ruleMap,
      transactionNum,
      includePossiblePaths,
      leafNodes[i],
      leafNodes[i].id,
      leafNodes[i].data.value || "",
      !isSelectionMode,
      null,
      1 // Only go one level deep.
    );

    // traverseActionPrerequisitesForNode(
    //   1,
    //   ruleMap,
    //   transactionNum,
    //   includePossiblePaths,
    //   leafNodes[i],
    //   leafNodes[i].id,
    //   leafNodes[i].data.value || "",
    //   !isSelectionMode,
    //   1 // Only go one level deep.
    // );
  }

  const fieldLists = buildFieldListsFromRuleMap(ruleMap);
  return {
    nodeList: finalNodeList,
    edgeList: finalEdgeList,
    rejections: fieldLists.rejectionList,
    responseFields: fieldLists.responseFieldList,
    modules: [],
  };
}

export function getNodeAndEdgeList(
  ruleMap,
  transactionNum = 0,
  includePossiblePaths = false,
  claimStatus,
  selectedRejection,
  selectedResponseField,
  showModules,
  selectedModuleId = "-1"
) {
  isRejection = false;
  isSelectionMode = false;
  isShowModulesMode = selectedModuleId === "-1" && showModules;
  selectedModule = selectedModuleId;

  //isReversal = claimStatus === "Reversed";
  nodeList = [];
  edgeList = [];
  modules = [];

  // If we are showing a specific module, select fields differently
  if (selectedModule !== "-1") {
    console.log(`Selecting nodes for module ${selectedModule}`);
    const moduleNodesAndEdges = getModuleNodeAndEdgeList(
      ruleMap,
      transactionNum,
      includePossiblePaths
    );
    return moduleNodesAndEdges;
  }

  const hasSelectedResponseField = selectedResponseField !== "-1";
  const hasSelectedRejection = selectedRejection !== "-1";

  // Check for any rejections first - these are in includedRejections and have their status's itemProcessed = true
  //   If we have any, default to showing only rejections and messages.
  let rejections = getRejectionList(
    ruleMap,
    transactionNum,
    hasSelectedRejection
  );
  if (hasSelectedRejection) {
    // If user has selected a rejection from the dropdown that is not in the list of rejections on this claim, put the renderer
    //   in "selection mode" so we can render everything using ghostly colors to indicate the selected rejection did not fire for
    //   the claim. We want only the list of included rejections for this - not all.
    const includedRejections = getRejectionList(ruleMap, transactionNum, false);
    if (
      includedRejections.filter((r) => r.displayKey === selectedRejection)
        .length < 1
    ) {
      isSelectionMode = true;
      console.log("isSelectionMode:", isSelectionMode);
    }

    // Filter to show only the selected rejection
    rejections = rejections.filter((r) => r.displayKey === selectedRejection);
  }
  const hasRejections = (rejections || []).length > 0;

  if (hasRejections && !hasSelectedResponseField) {
    isRejection = true;
    populateNodesAndEdgesForRejections(
      ruleMap,
      transactionNum,
      includePossiblePaths,
      rejections
    );
  }

  // Always show: all transmission-level messages and only the transaction messages for the current transaction
  const messages = getMessageList(ruleMap, transactionNum);
  const hasMessages = (messages || []).length > 0;

  if (hasMessages && !hasSelectedResponseField && !hasSelectedRejection) {
    populateNodesAndEdgesForMessages(
      ruleMap,
      transactionNum,
      includePossiblePaths,
      messages
    );
  }

  // Show response fields if there were no errors above and this isn't a reversal.
  let responseFields = getResponseFieldList(ruleMap, transactionNum);

  if (hasSelectedResponseField) {
    // Show only the selected response field
    responseFields = responseFields.filter(
      (r) => r.displayKey === selectedResponseField
    );
  }
  const hasResponseFields = (responseFields || []).length > 0;

  // Don't show response fields if this is a rejection or reversal, unless a specific response field has been selected.
  const shouldShowResponseFields =
    hasResponseFields &&
    !hasSelectedRejection &&
    (!hasRejections || hasSelectedResponseField);

  if (shouldShowResponseFields) {
    populateNodesAndEdgesForResponseFields(
      ruleMap,
      transactionNum,
      includePossiblePaths,
      responseFields
    );
  }

  // If this is a reversal and a specific response field or rejection has not been selected AND if there are no rejections, show
  //   all processed artifacts and included data values instead of response fields.
  const shouldShowProcessedArtifactsAndResponseFields = false;
  // isReversal &&
  // !hasRejections &&
  // !hasSelectedRejection &&
  // !hasSelectedResponseField;
  if (shouldShowProcessedArtifactsAndResponseFields) {
    populateNodesAndEdgesForProcessedArtifacts(
      ruleMap,
      transactionNum,
      includePossiblePaths,
      ruleMap.codeArtifacts
    );
    populateNodesAndEdgesForProcessedIncludedDataValues(
      ruleMap,
      transactionNum,
      includePossiblePaths,
      ruleMap.includedDataValues
    );
  }

  const fieldLists = buildFieldListsFromRuleMap(ruleMap);
  let finalNodeList = [...nodeList];
  let finalEdgeList = [...edgeList];

  if (isShowModulesMode) {
    let moduleNode;
    for (let i = 0; i < modules.length; i++) {
      // Build a node for the module that will replace its subnodes - but only if in show modules node
      moduleNode = buildModuleNode(modules[i]);
      nodeList.push(moduleNode);
    }

    // Remove all hidden nodes
    const finalLists = removeHiddenNodesAndEdges();
    finalNodeList = finalLists.finalNodeList;
    finalEdgeList = finalLists.finalEdgeList;
    // console.log("Final nodes", finalNodeList);
  }

  return {
    nodeList: finalNodeList,
    edgeList: finalEdgeList,
    rejections: fieldLists.rejectionList,
    responseFields: fieldLists.responseFieldList,
    modules: modules.sort((a, b) => (a > b ? 1 : -1)),
  };
}
